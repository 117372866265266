<template>
  <div id="contact" :class="contactClass">
    {{ t("single.contactformtel") }}
    <a class="tel" :href="'tel:' + property.tel_no">
      {{ telNo }}
    </a>
    {{ t("single.contactformweb") }}
    <a :href="contact_url" class="form" target="_blank">{{
      t("single.contactform")
    }}</a>
  </div>
</template>

<script>
import { urlLinkSettingMixin } from "@/common/mixins/mixins.js";
export default {
  mixins: [urlLinkSettingMixin],
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contact_url: "https://www.lead-real.co.jp/contact/contact.php",
    };
  },
  computed: {
    contactClass() {
      return "contact-" + this.$store.state.lang;
    },
    telNo() {
      return (this.langIsZh() || this.langIsEn()) && !!this.property.tel_no
        ? "+81-" + this.property.tel_no.slice(1)
        : this.property.tel_no;
    },
  },
};
</script>

<style lang="scss" scoped>
#contact {
  line-height: 2;
}

.contact-ja {
  background: #fafafa;
  font-weight: 700;
  margin-top: 50px;
  padding: 100px 0 100px;
  text-align: center;

  a {
    color: #cc9c34;
    display: block;
    font-size: 320%;
    letter-spacing: 0.1em;
    margin: 0 auto 25px;
    text-align: center;
    text-indent: 0.1em;
    width: 80%;
    max-width: 500px;
  }

  .form {
    background: #cc9c34;
    color: #fff;
    font-size: 115%;
    line-height: 70px;
    margin: 25px auto 0;
  }
}

.contact-zh {
  background: #001334;
  font-weight: 500;
  margin-top: 50px;
  padding: 100px 0 100px;
  color: white;
  text-align: center;

  a {
    color: #d7aa3b;
    display: block;
    font-size: 300%;
    letter-spacing: 0.1em;
    margin: 0 auto 25px;
    text-align: center;
    text-indent: 0.1em;
    width: 80%;
    max-width: 500px;
  }

  .form {
    background: #b90f0f;
    color: #efc96b;
    font-size: 115%;
    line-height: 90px;
    margin: 25px auto 0;
  }
}

.contact-en {
  background: #001334;
  font-weight: 500;
  margin-top: 50px;
  padding: 100px 0 100px;
  color: white;
  text-align: center;

  a {
    color: #d7aa3b;
    display: block;
    font-size: 300%;
    letter-spacing: 0.1em;
    margin: 0 auto 25px;
    text-align: center;
    text-indent: 0.1em;
    width: 80%;
    max-width: 500px;
  }

  .form {
    background: #b90f0f;
    color: #efc96b;
    font-size: 115%;
    line-height: 90px;
    margin: 25px auto 0;
  }
}
</style>
