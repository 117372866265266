<template>
  <div class="top">
    <div class="hero" :class="langState()">
      <div class="copy serif" :class="langState()">
        <span>{{ t("home.copy1") }}</span
        ><span>{{ t("home.copy2") }}</span>
      </div>
      <router-link v-if="langIsJa()" :to="{ name: 'About' }" class="about">
        このサイトについて
      </router-link>
      <router-link v-if="langIsEn()" :to="{ name: 'About' }" class="about">
        About this website
      </router-link>
      <router-link v-if="langIsZh()" :to="{ name: 'About' }" class="about">
        About this website
      </router-link>
    </div>
    <div class="list col2">
      <div class="sub">
        <h2 :class="'sub-main-' + langState()" style="margin-bottom: 10px">
          {{ t("header.properties") }}
        </h2>
        <span v-if="langIsJa()" class="sub-sub"></span>
        <span v-else-if="langIsZh()" class="sub-sub" :class="langState()"
          >PROPERTIES</span
        >
      </div>
      <div class="map" id="mapArea">
        <Map />
      </div>
    </div>
    <div class="list col2">
      <div class="sub">
        <h2 :class="'sub-main-' + langState()">
          {{ t("home.pickup") }}
        </h2>
        <span v-if="langIsJa()" class="sub-sub">あなたにおすすめの物件</span>
        <span v-else-if="langIsZh()" class="sub-sub" :class="langState()"
          >PICKUP</span
        >
      </div>
      <ul class="pickup">
        <li
          v-for="(item, index) in recommendItems"
          :key="index"
          :class="langState()"
        >
          <router-link
            :to="'/single/' + item.property_id"
            :class="{ disabled: isSigned(item) }"
          >
            <PropertyEn v-if="langIsEn()" :property="item" />
            <PropertyZh v-else-if="langIsZh()" :property="item" />
            <Property v-else :property="item" />
          </router-link>
        </li>
      </ul>
    </div>

    <div class="list col3" :class="langIsEn() ? 'col3-margin' : ''">
      <div class="sub">
        <h2 :class="'sub-main-' + langState()">
          {{ t("home.newarrival") }}
        </h2>
        <span v-if="langIsJa()" class="sub-sub">新着物件</span>
        <span v-else-if="langIsZh()" class="sub-sub" :class="langState()"
          >NEW ARRIVAL</span
        >
      </div>
      <ul>
        <li>
          <div v-if="langIsJa()">
            <a href="https://member.glocaly.tokyo/ja" class target="_blank">
              <div class>
                <span class="thumb">
                  <img :src="ad_banner_img_src" alt="thumb" />
                </span>
                <span>
                  <strong>Glocalyの会員登録へ</strong>
                </span>
              </div>
            </a>
          </div>
          <div v-else-if="langIsZh()">
            <a href="https://member.glocaly.tokyo/zh-cn" class target="_blank">
              <div class>
                <span class="thumb">
                  <img :src="ad_banner_img_src" alt="thumb" />
                </span>
                <span>
                  <strong>要会员注册到Glocaly</strong>
                </span>
              </div>
            </a>
          </div>
          <div v-else>
            <a href="https://member.glocaly.tokyo/en" class target="_blank">
              <div class>
                <span class="thumb">
                  <img :src="ad_banner_img_src" alt="thumb" />
                </span>
                <span>
                  <strong>Glocaly Membership Registration</strong>
                </span>
              </div>
            </a>
          </div>
        </li>
        <li
          v-for="(item, index) in newArrivalItems"
          :key="index"
          :class="langState()"
        >
          <router-link
            :to="'/single/' + item.property_id"
            :class="{ disabled: isSigned(item) }"
          >
            <PropertyEn v-if="langIsEn()" :property="item" />
            <PropertyZh v-else-if="langIsZh()" :property="item" />
            <Property v-else :property="item" />
          </router-link>
        </li>
      </ul>
      <div class="more-button" :class="langIsEn() ? 'more-button-margin' : ''">
        <router-link :to="{ name: 'Archive' }">
          <UnderLined v-if="langIsEn()" text="MORE" />
          <UnderLinedYellow
            v-else-if="langIsZh()"
            :class="langState()"
            text="浏览更多"
          />
          <SquareButton v-else text="すべて見る" width="250px" height="50px" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { isSigned } from "@/common/mixins/mixins.js";
import Map from "@/components/Map.vue";
import {
  Property,
  PropertyEn,
  PropertyZh,
  SquareButton,
  UnderLined,
  UnderLinedYellow,
} from "@/components";
import axios from "axios";
import CONSTANTS from "@/common/constants/constants";
const API_URL_PREFIX = CONSTANTS.API_URL_PREFIX;

export default {
  name: "Home",
  mixins: [isSigned],
  components: {
    Map,
    Property,
    PropertyEn,
    PropertyZh,
    SquareButton,
    UnderLined,
    UnderLinedYellow,
  },

  data() {
    return {
      recommendItems: [],
      recommendItemsEn: [],
      newArrivalItems: [],
      newArrivalItemsEn: [],
      // TODO: s3保存しているがバケットがpublic公開なのでアクセスを制限するように変更する
      ad_banner_img_src:
        "https://leadreal-pj-application-assets.s3.ap-northeast-1.amazonaws.com/glocaly.jpg",
    };
  },

  mounted() {
    let route = this.$route;
    if (route.meta.title) {
      document.title = route.meta.title;
    }
  },

  created() {
    axios
      .get(
        API_URL_PREFIX +
          "/site_api/v1/properties/sort_by_updated_at?page=1&per_page=9&lang=" +
          this.$store.state.lang
      )
      .then((response) => {
        let properties = [...response.data];
        let recommend = properties.slice(0, 4);
        this.recommendItems = this.recommendItems.concat(recommend);
        let newArrival = properties.slice(4);
        this.newArrivalItems = this.newArrivalItems.concat(newArrival);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  computed: {
    console: () => console,
    window: () => window,
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/styles/mixins";

.top .hero {
  margin: 0 auto;
  width: 100%;
  height: 40vw;
  // min-height: 440px;
  position: relative;
  background: #000 url(../assets/img/global.jpg) center center no-repeat;
  background-size: contain;

  .copy {
    font-size: 300%;
    letter-spacing: 0.5em;
    text-align: center;
    text-indent: 0.5em;
    width: 100%;
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
    color: #fff;
    text-shadow: 0px 0px 20px #000, 0px 0px 30px #000, 0px 0px 40px #000;

    .copy-sub {
      font-size: 33%;
      margin-top: 30px;
    }
    span {
      display: inline-block;
    }
  }

  .about {
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid #fff;
    color: #fff;
    display: block;
    font-size: 90%;
    font-weight: 700;
    letter-spacing: 0.2em;
    line-height: 50px;
    text-align: center;
    text-indent: 0.2em;
    width: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 80px);
  }
}
@media (max-width: 650px) {
  .top .hero {
    min-height: 400px;
    background: #000 url(../assets/img/global_sp.png) center center no-repeat;
    background-size: cover;
    background-position: right;
  }
}
@media (min-width: 651px) {
  .top .hero {
    max-height: 100%;
    background: #000 url(../assets/img/global.jpg) center center no-repeat;
    background-size: cover;
  }
}

li {
  &.en {
    border: none;
  }

  &.zh {
    border: none;
  }
}

.col3-margin {
  margin-top: 200px;
}

.more-button {
  font-size: 90%;
  letter-spacing: 0.2em;
  text-align: center;
  margin: 75px 0;

  &.more-button-margin {
    margin-top: 120px;
  }
}
</style>
