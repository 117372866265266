<template>
  <!-- <div class="under-lined"> -->
  <span>{{ text }}</span>
  <!-- </div> -->
</template>

<script>
export default {
  name: "UnderLined",
  props: {
    text: String,
  },
};
</script>

<style lang="scss" scoped>
// .under-lined {
// text-align: left;

span {
  font-size: 0.8em;
  position: relative;
  display: inline-block;

  &:after {
    content: "";
    position: absolute;
    display: block;
    border-bottom: solid 1px #000;
    width: 100%;
    bottom: -10px;
  }
}
// }
</style>
