// チャットボットの送信ボタンはチャットボット側で実装しています。
<template>
  <img src="@/common/icons/together.png" width="135" height="111" />
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
img {
  position: absolute;
  top: -56px;
  right: 30px;
}
</style>
