<template>
  <div id="main" class="sub">
    <GoogleMap
      api-key="AIzaSyDOckyffOPNjxTVo6UbNjvnSm_dVIWeMmU"
      style="width: 100%; height: 500px"
      :center="center"
      :zoom="12"
    >
      <MarkerCluster>
        <Marker
          v-for="property in properties"
          :options="{
            position: { lat: property.latitude, lng: property.longitude },
          }"
          :key="property.property_id"
          @click="selectedLocation = property"
        >
          <InfoWindow
            v-model="infowindow"
            :position="selectedLocation"
            @closeclick="closeInfoWindow"
          >
            <div
              :key="property.property_id"
              :class="langState()"
              class="sub-sub"
            >
              <div class="map-modal">
                <router-link
                  :to="'/single/' + property.property_id"
                  :class="{ disabled: false }"
                >
                  <PropertyEn v-if="langIsEn()" :property="property" />
                  <PropertyZh v-else-if="langIsZh()" :property="property" />
                  <Property v-else :property="property" />
                </router-link>
              </div>
            </div>
          </InfoWindow>
        </Marker>
      </MarkerCluster>
    </GoogleMap>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import { GoogleMap, Marker, MarkerCluster, InfoWindow } from "vue3-google-map";
import axios from "axios";
import CONSTANTS from "@/common/constants/constants";
import { Property, PropertyEn, PropertyZh } from "@/components";
const API_URL_PREFIX = CONSTANTS.API_URL_PREFIX;

export default defineComponent({
  components: {
    GoogleMap,
    Marker,
    MarkerCluster,
    InfoWindow,
    Property,
    PropertyEn,
    PropertyZh,
  },
  props: {
    property: Object,
  },

  setup() {
    const center = { lat: 35.675558, lng: 139.737728 };

    const selectedLocation = ref(null);

    const infowindow = ref(true);

    watch(infowindow, (v) => {
      alert("infowindow has been " + (v ? "opened" : "closed"));
    });

    return { center, selectedLocation, infowindow };
  },

  data() {
    return {
      properties: [],
    };
  },

  created() {
    axios
      .get(
        API_URL_PREFIX +
          "/site_api/v1/properties/sort_by_updated_at?page=1&per_page=20&lang=" +
          this.$store.state.lang
      )
      .then((response) => {
        this.properties = response.data.filter((property) => {
          return property?.latitude != null && property?.longitude != null;
        });
      })
      .catch((error) => {
        console.log("API Error: " + error);
      });
  },
});
</script>

<style lang="scss">
.map-modal {
  width: 300px;
  height: 200px;
  .thumb {
    width: 300px;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
