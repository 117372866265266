<template>
  <div id="main" class="terms">
    <div v-if="langIsJa()">
      <h1 class="sub">
        TERMS<br /><span class="ja">クラウドサービス利用規約</span>
      </h1>

      <h2>第１条（本規約の目的）</h2>
      <p>
        本規約は、株式会社株式会社リード・リアルエステート（以下「当社」という。）の提供するオンラインサービス「REAL
        ESTATE ZONE AI＋MOVIE」
        （以下「本サービス」という。）について定めるものとします。
      </p>

      <h2>第２条（用語の定義）</h2>
      <p>
        本規約においては、次の用語はそれぞれ次の意味で使用します。<br />
        （１）利用契約：本規約に基づき当社と利用者との間に締結される本サービスの提供に関する契約<br />
        （２）利用者：会員登録によりＩＤの付与を受けることなく、本サービスを利用する者
      </p>

      <h2>第３条（本規約の適用）</h2>
      <p>
        当社は、利用契約の内容に従って本サービスの提供を行い、利用者は利用契約及び当社が定める条件にてこれを利用するものとします。
      </p>

      <h2>第４条（本規約の変更）</h2>
      <ol>
        <li>
          当社は、利用者の事前の承諾を得ることなく、本規約を随時変更できるものとします。本規約が変更された後のサービスの提供条件は、変更後の新利用規約に従うものとします。
        </li>
        <li>
          当社は、前項の変更を行う場合は、１４日以上の予告期間をおいて、変更後の新利用規約の内容を利用者に通知または本サービス上に表示するものとします。ただし、変更が軽微で利用者に特に不利益にならないと当社が判断した場合は、通知しないものとします。
        </li>
      </ol>

      <h2>第５条（利用契約の承諾）</h2>
      <ol>
        <li>
          利用者は、本規約の内容を承諾の上、当社が定める方法により、本サービスを利用するものとします。利用契約は、利用者が本規約に「同意する」を選択された時点をもって、本規約の内容は利用者により承諾され、利用契約が成立したものとみなします。
        </li>
        <li>
          当社は、前項の規定にかかわらず、次の場合には、当社の判断でその利用者による利用を制限又は利用契約を解除することができるものとします。<br />
          （１）本サービスの利用目的が、評価、解析その他本来の目的と異なるものであると疑われる場合<br />
          （２）利用者またはその代表者、役員において、反社会的勢力（暴力団、暴力団員等をいう。）に該当するとき、またはそのおそれがあるとき<br />
          （３）本規約に違反したとき<br />
          （４）その他当社が不適当と判断する相当の理由がある場合
        </li>
        <li>
          前項に従い、当社が利用者の利用を制限又は利用契約を解除したことによる責任は負いません。
        </li>
      </ol>

      <h2>第６条（サービスの範囲）</h2>
      <p>
        当社は、当社指定の条件下で、利用者が管理する端末機器（スマートフォン、携帯電話、パソコン等をいう。以下「端末機器」という。）から電気通信回線を経由して当社の指定サーバに接続することにより、本サービスを利用することのできる環境を提供します。
      </p>

      <h2>第７条（利用制限）</h2>
      <ol>
        <li>
          本サービスは、利用者自身が、自ら物件購入のために利用することを目的として提供されるものであり、商業目的で使用（第三者に対し有償と無償の別にかかわらず、第三者に対してサービス等を提供することなど）することはできません。
        </li>
        <li>
          利用者による本サービスの利用は端末機器から当社指定のＵＲＬへ接続することにより行われるものとし、本サービスを構成するソフトウェア自体をダウンロードしたり、コピーする等の方法により本サービスを構成するソフトウェアを入手することはできません。
        </li>
      </ol>

      <h2>第８条（本サービスの変更）</h2>
      <p>
        当社は、本サービスの機能追加、改善を目的として、当社の裁量により本サービスの仕様変更やデザイン変更、運用上のシステムの変更その他一部の追加・変更を行うことがあります。ただし、当該追加・変更によって、変更前の本サービスのすべての機能・性能が維持されることを保証するものではありません。
      </p>

      <h2>第９条（サービスレベル）</h2>
      <p>
        当社は、本サービスのサービスレベルの向上に努めます。但し、本サービスに関する品質・性能、サービスレベルについて保証するものではありません。
      </p>

      <h2>第１０条（電気通信回線）</h2>
      <p>
        利用者が使用する端末機器から本サービスに接続する電気通信回線は、利用者自身の責任と費用負担において、確保、維持されるものとし、当社は一切の責任を負いません。
      </p>

      <h2>第１１条（データ管理）</h2>
      <ol>
        <li>
          利用者は、本サービスの利用に関連して入力、提供または伝送するデータ等について、必要な情報は自己の責任で保全しておくものとします。
        </li>
        <li>
          当社は、利用者が利用する情報に関して、本サービスを提供する設備等の故障等により滅失した場合に、その情報を復元する目的でこれを別に記録して一定期間保管しますが、復元の義務を負うものではありません。
        </li>
      </ol>

      <h2>第１２条（個人情報の管理）</h2>
      <ol>
        <li>
          本サービスはＡＩを利用して当社が利用者の個人情報を取得することがあります。
        </li>
        <li>
          当社は、本サービスに入力されるデータに個人情報が含まれていた場合、本サービス提供の目的以外で利用しないものとし、個人情報の保護に関する法律及び当社個人情報保護方針（プライバシーポリシー）に基づいて、紛失・破壊・改竄・漏洩等の危険から保護するための合理的な安全管理措置を講じ、厳重に管理するものとします。
        </li>
        <li>
          当社は、本サービスの提供のため必要がなくなった個人情報に関して、一切のコピーを残すことなく、当社責任のもとで速やかに破棄するものとします。
        </li>
        <li>
          本条の規定は、利用契約が終了した後も有効に存続するものとします。
        </li>
      </ol>

      <h2>第１３条（当社による情報の管理・利用）</h2>
      <ol>
        <li>
          当社は、本サービスの改良、サービスの維持管理等を目的とする統計調査のため、利用者の本サービスの利用状況、画面・項目の利用頻度等の統計数値を利用し、あるいは統計調査に必要な限度でこれらの情報を解析し、二次加工して活用するものとし、利用者はかかる統計調査、二次加工活用を行うことに同意します。
        </li>
        <li>
          当社は、利用者が入力したデータに関し、善良な管理者による注意をもって機密保持とその管理に努めるものとします。
        </li>
        <li>
          利用者は、当社が、裁判所、その他の法的な権限のある官公庁の命令等により本サービスに関する情報の開示ないし提出を求められた場合は、かかる命令等に従って情報の開示ないし提出をすることがあることを承諾し、かかる開示ないし提出に対して異議を述べないものとします。
        </li>
      </ol>

      <h2>第１４条（委託）</h2>
      <p>
        当社は本サービスの提供に関する業務の全部もしくは一部を利用者の承諾なしに、第三者に委託することができます。ただし、その場合、当社は責任をもって委託先を管理するものとします。
      </p>

      <h2>第１５条（禁止行為）</h2>
      <p>
        利用者は、本サービスを利用するにあたり、以下の行為を行わないものとします。<br />
        （１）法令に違反する行為またはそのおそれがある行為<br />
        （２）公序良俗に反する行為<br />
        （３）他の利用者の利用を妨害する行為またはそのおそれがある行為<br />
        （４）本サービスを構成するハードウェアまたはソフトウェアへの不正アクセス行為、クラッキング行為その他設備等に支障を与える等の行為<br />
        （５）本サービスの提供を妨害する行為またはそのおそれがある行為<br />
        （６）本サービスを構成するソフトウェアの解析、リバースエンジニアリングその他ソースコードを入手しようとする行為<br />
        （７）他人になりすまして利用する行為またはそれを試みる行為<br />
        （８）他の利用者のデータを閲覧、変更、改竄する行為またはそのおそれがある行為
      </p>

      <h2>第１６条（知的財産権）</h2>
      <p>
        本サービスを構成する有形・無形の構成物（ソフトウェアプログラム、データベース、アイコン、画像、文章、マニュアル等の関連ドキュメント等を含む。）に関する著作権を含む一切の知的財産権、その他の権利は、当社または当社に許諾した第三者に帰属します。
      </p>

      <h2>第１７条（自己責任の原則）</h2>
      <ol>
        <li>
          利用者は、本サービスの利用及び本サービス内における一切の行為（情報の登録、閲覧、削除、送信等）及びその結果について、一切の責任を負います。
        </li>
        <li>
          利用者は、本サービスの利用に伴い、自己の責めに帰すべき事由で第三者に対して損害を与えた場合、または第三者からクレーム等の請求がなされた場合、自己の責任と費用をもって処理、解決するものとします。
        </li>
        <li>
          利用者は、利用者がその故意または過失により当社に損害を与えた場合、当社に対して、当該損害の賠償を行うものとします。
        </li>
      </ol>

      <h2>第１８条（保証の制限）</h2>
      <ol>
        <li>
          当社は、本サービスが、重要な点において、実質的に正常に提供されることを保証します。
        </li>
        <li>
          当社は、本サービスを構成するソフトウェアにバグ等の瑕疵のないことや、本サービスが利用者の特定の利用目的に合致することを保証するものではありません。また、当社は、端末機器において他のソフトウェア等が使用ないし併用された場合の、本サービスの正常な動作を保証するものではありません。
        </li>
        <li>
          本サービスに重要な瑕疵が認められた場合、本サービスの修正ないし瑕疵の除去の努力をしますが、当社の責任はこれに限られるものとし、本サービスに、瑕疵がないこと、一定の品質・性能を満たすことまで保証するものではありません。
        </li>
        <li>本条は、本サービスに関する唯一の保証について述べたものです。</li>
      </ol>

      <h2>第１９条（免責及び損害賠償の制限）</h2>
      <ol>
        <li>
          当社は、本規約の各条項に従って制限された限度においてのみ、本サービスについての責任を負うものとします。当社は、本規約の各条項において保証しないとされている事項、責任を負わないとされている事項、利用者の責任とされている事項については、一切の責任を負いません。
        </li>
        <li>
          当社は、本サービスに起因して、利用者の事業機会の損失、逸失利益、データ滅失・損壊によって生じた損害について、契約責任、不法行為責任その他請求の原因を問わず、いかなる賠償責任も負いません。
        </li>
      </ol>

      <h2>第２０条（本サービスの停止・廃止）</h2>
      <ol>
        <li>
          当社は、以下のいずれかに該当する場合には、利用者に事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。<br />
          （１）本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合<br />
          （２）コンピューター、通信回線等が事故により停止した場合<br />
          （３）火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合<br />
          （４）本サービスのメンテナンスを行う場合<br />
          （５）第三者による妨害行為等により本サービスの継続が利用者に重大な支障を与えるおそれがあると判断される場合<br />
          （６）その他、当社が停止又は中断を必要と判断した場合
        </li>
        <li>
          当社は、前項の事由の発生又は当社の都合により、本サービスの一部または全部を何時でも廃止できる権利を有します。
        </li>
        <li>
          当社は、本条に基づき当社が行った措置に基づき利用者に生じた不利益・損害について一切の責任を負いません。
        </li>
      </ol>

      <h2>第２１条（契約終了後の処理）</h2>
      <ol>
        <li>
          利用者は、理由の如何を問わず利用契約が終了した場合、以後、本サービスを利用することはできません。
        </li>
        <li>
          当社は、理由の如何を問わず利用契約が終了した場合、本サービスに格納された一切のデータを消去したことによって利用者に生じた損害を賠償する義務を負わないものとします。
        </li>
      </ol>

      <h2>第２２条（通知）</h2>
      <p>
        本サービスに関する通知その他本規約に定める当社から利用者に対する通知は、本サービス上に表示、電子メールによる方法その他当社の定める方法によって行うものとします。通知は、当社からの表示、発信によってその効力が生ずるものとします。
      </p>

      <h2>第２３条（権利義務譲渡の禁止）</h2>
      <p>
        利用者は、利用契約の契約上の地位を第三者に承継させ、または利用契約に基づく権利義務の全部または一部を第三者に譲渡し、承継させ、または担保に供してはならないものとします。
      </p>

      <h2>第２４条（不可抗力）</h2>
      <p>
        当社は、天災、法令・規則の制定・改廃、その他の不可抗力によって本サービスの履行が妨げられた場合には、利用契約その他の一切の規定にかかわらず、かかる不可抗力によって利用者に生じた損害について一切の責任を負担しません。
      </p>

      <h2>第２５条（協議）</h2>
      <p>
        本規約の解釈について両当事者間に異議、疑義が生じた場合、または本規約に定めのない事項が生じた場合、誠実に協議し、円満にその解決を図るものとします。
      </p>

      <h2>第２６条（準拠法及び裁判管轄）</h2>
      <p>
        利用契約に関する事項については、日本法を準拠法とし、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
      </p>

      <h2>附則</h2>
      <p>本規約は２０２１年１月１日から実施します。</p>

      <p class="right">以上</p>
    </div>
    <div v-else-if="langIsEn()">
      <h1 class="sub">
        TERMS<br /><span class="ja">Terms of Use for Cloud Services</span>
      </h1>

      <h2>Article 1 (Purpose of the Code)</h2>
      <p>
        This Agreement shall govern the on-line service "REAL ESTATE ZONE
        AI+MOVIE" (hereinafter referred to as the "Service") provided by Reed
        Real Estate Co., Ltd. (hereinafter referred to as the "Company").
      </p>

      <h2>Article 2 (Definition of terms)</h2>
      <p>
        In this Agreement, the following terms are used in each of the following
        meanings:<br />
        (1)User Agreement: Agreement for the provision of the Services entered
        into between us and the User pursuant to this Agreement.<br />
        (2)Users: Users of the Services without ID from membership registration
      </p>

      <h2>Article 3 (Application of the Code)</h2>
      <p>
        We shall provide the Services in accordance with the terms and
        conditions of the User Agreement and the Users shall use the Services in
        accordance with the terms and conditions set forth by us.
      </p>

      <h2>Article 4 (Changes to this Agreement)</h2>
      <ol>
        <li>
          We may change this Agreement at any time without prior consent of the
          user. The terms and conditions for the provision of services after
          these Terms and Conditions have been amended shall be subject to the
          revised Terms and Conditions of Use.
        </li>
        <li>
          In the event of any change as set forth in the preceding paragraph,
          the Company shall notify the user of the contents of the revised New
          Terms of Use or indicate said contents on the Service with a notice
          period of 14 days or more. Provided, however, that no notice shall be
          given if the change is minor and it is determined by us not to be
          specifically disadvantageous to the user.
        </li>
      </ol>

      <h2>Article 5 (Acceptance of Use Contract)</h2>
      <ol>
        <li>
          Users shall accept the contents of these Terms and Conditions and use
          the Services in the manner prescribed by Us. The contents of this
          Agreement shall be deemed to have been approved by the user and the
          usage agreement shall be deemed to have been concluded at the time
          when the user chooses to "agree" to this Agreement.
        </li>
        <li>
          Notwithstanding the provisions of the preceding paragraph, the Company
          may, at its discretion, restrict or terminate the Use Agreement by the
          user in any of the following cases:<br />
          (1)In the event the Purpose of Use of the Service is suspected to be
          different from the Purpose of Evaluation, Analysis, or otherwise.<br />
          (2)When an anti-social force (referring to an organized crime group,
          an organized crime group member, etc.) is or is likely to be committed
          by a user, a representative of the user, or an officer of the user<br />
          (3)In the event of a breach of this Agreement<br />
          (4) In the event there are other reasonable grounds to believe that we
          are inappropriate.
        </li>
        <li>
          accordance with the preceding clause, we assume no responsibility for
          restricting the use of the user or terminating the use agreement.
        </li>
      </ol>

      <h2>Article 6 (Scope of Services)</h2>
      <p>
        We provide an environment in which the Services can be used by
        connecting terminal equipment (smartphones, mobile phones, personal
        computers, etc.; hereinafter referred to as "terminal equipment")
        managed by the user to our designated server via a telecommunications
        line under the conditions specified by us.
      </p>

      <h2>Article 7 (Restriction of Use)</h2>
      <ol>
        <li>
          This service is provided by the user himself/herself for the purpose
          of using it for the purchase of the property and cannot be used for
          commercial purposes (e.g. providing services, etc. to a third party
          with or without charge to a third party).
        </li>
        <li>
          The use of the Service by the user shall be made by connecting the
          Service to URL designated by us from the terminal equipment, and the
          software that comprises the Service cannot be obtained by downloading
          or copying the software that comprises the Service.
        </li>
      </ol>

      <h2>Article 8 (Change of Service)</h2>
      <p>
        We may, at our discretion, change the specifications or design of the
        Services, change the operational system, or make some other additions or
        modifications to the Services for the purpose of adding or improving the
        functions of the Services. However, such addition or change does not
        guarantee that all functions and performance of the Service before the
        change will be maintained.
      </p>

      <h2>Article 9 (Service Level)</h2>
      <p>
        We strive to improve the service level of the Service. However, the
        quality, performance, and service level of this service are not
        guaranteed.
      </p>

      <h2>Article 10 (Telecommunications Lines)</h2>
      <p>
        Telecommunications lines connected to the Service from terminal
        equipment used by the user shall be secured and maintained at the user's
        own responsibility and expense, and we shall not be responsible in any
        way.
      </p>

      <h2>Article 11 (Data Management)</h2>
      <ol>
        <li>
          Users shall, at their own responsibility, maintain necessary
          information on data entered, provided, or transmitted in connection
          with the use of the Services.
        </li>
        <li>
          In the event the information used by the user is lost due to a
          malfunction of the equipment that provides the Services, the Company
          shall store such information separately for the purpose of restoring
          such information for a certain period of time, but the Company shall
          not assume any obligation to restore such information.
        </li>
      </ol>

      <h2>Article 12 (Management of Personal Information)</h2>
      <ol>
        <li>This service may be used by AI to obtain personal data of users</li>
        <li>
          We shall not use any personal information contained in the data
          entered into this service for any purpose other than the provision of
          this service, and shall take reasonable safety control measures to
          protect against risks such as loss, destruction, falsification, and
          leakage and strict control in accordance with the Personal Information
          Protection Law and our Personal Information Protection Policy (Privacy
          Policy).
        </li>
        <li>
          We shall promptly destroy, under our responsibility, any and all
          copies of the Personal Information that is no longer necessary for the
          provision of the Services.
        </li>
        <li>
          The provisions of this article shall survive the termination of the
          Utilization Agreement.
        </li>
      </ol>

      <h2>Article 13 (Management and use of information by us)</h2>
      <ol>
        <li>
          For the purpose of statistical surveys for the purpose of improving
          the Service, maintaining and managing the Service, the Company shall
          utilize statistical data such as the status of use of the Service by
          the user, the frequency of use of screens and items, etc., or analyze
          such information within the limit necessary for statistical surveys
          and utilize such data through secondary processing, and the user
          agrees to implement such statistical surveys and secondary processing.
        </li>
        <li>
          We shall endeavor to maintain and manage the confidentiality of the
          data entered by the user with the care of a good manager
        </li>
        <li>
          In the event the User is requested to disclose or submit information
          related to the Services by the court or other legally authorized
          public agency, the User shall accept that the Information may be
          disclosed or submitted in accordance with such order, and shall not
          object to such disclosure or submission.
        </li>
      </ol>

      <h2>Article 14 (entrustment)</h2>
      <p>
        We may entrust all or part of our services related to the provision of
        the Services to a third party without the consent of the user. Provided,
        however, that in such a case, we shall be responsible for managing the
        outsourcee.
      </p>

      <h2>Article 15 (Prohibited Acts)</h2>
      <p>
        Users shall not engage in any of the following acts when using the
        Services.<br />
        (1)Acts that violate or may violate laws and regulations<br />
        (2)Act contrary to public order and morality<br />
        (3)Acts that interfere with or may interfere with the use of other
        users<br />
        (4)Unauthorized access to hardware or software that constitutes the
        Services, cracking, or other acts that interfere with the facilities,
        etc.<br />
        (5)Acts that interfere with or may interfere with the provision of the
        Services<br />
        (6)Any attempt to analyze, reverse engineer, or otherwise obtain source
        code for the software that comprises the Services;<br />
        (7)Acts that pretend to be used by others or attempts to do so<br />
        (8)Acts that peruse, modify, or alter data of other users, or acts that
        are likely to do so
      </p>

      <h2>Article 16 (Intellectual Property Rights)</h2>
      <p>
        All intellectual property rights and other rights, including copyrights,
        with respect to the tangible and intangible components (including
        software programs, databases, icons, images, text, manuals, and other
        related documents) that comprise the Services, belong to us or to a
        third party licensed to us.
      </p>

      <h2>Article 17 (Principle of self-responsibility)</h2>
      <ol>
        <li>
          The user shall be responsible for the use of the Service, all acts
          within the Service (registration, inspection, deletion, transmission,
          etc. of information), and the results thereof.
        </li>
        <li>
          In the event the user causes damage to a third party due to reasons
          attributable to the user due to the use of the Service, or in the
          event a claim, etc., is filed by a third party, the user shall handle
          and resolve such claim at his/her own responsibility and expense.
        </li>
        <li>
          The user shall compensate us for any damage caused to us by the intent
          or negligence of the user.
        </li>
      </ol>

      <h2>Article 18 (Limitation of Warranty)</h2>
      <ol>
        <li>
          We warrant that the Services will be provided substantially in a
          substantially normal manner in a material respect.
        </li>
        <li>
          We do not guarantee that the software that comprises the Service is
          free from defects such as bugs, or that the Service meets the specific
          purpose of use of the user. In addition, we do not guarantee the
          normal operation of this service if other software is used or used
          together with the terminal equipment.
        </li>
        <li>
          In the event of any material defect in the Services, we will make
          efforts to correct or eliminate any defect in the Services. However,
          our responsibility shall be limited to this, and we do not guarantee
          that the Services will be free from defect or meet certain quality and
          performance.
        </li>
        <li>
          This clause states the only warranty with respect to the Services.
        </li>
      </ol>

      <h2>Article 19 (Disclaimer and Limitation on Damages)</h2>
      <ol>
        <li>
          We shall only be liable for the Services to the extent limited in
          accordance with the terms and conditions of this Agreement. We shall
          not assume any responsibility with respect to matters that are not
          guaranteed or liable under any of the provisions of this Agreement,
          matters that are not liable, or matters that are the responsibility of
          the user.
        </li>
        <li>
          We shall not be liable for any damages arising from the loss of
          business opportunities, loss of profit, loss of data, or destruction
          caused by the Services, regardless of contractual liability, tort
          liability, or any other cause of claim.
        </li>
      </ol>

      <h2>Article 20 (Suspension and Abolition of Service)</h2>
      <ol>
        <li>
          In the event of any one of the following, the Company may suspend or
          suspend the use of the Services in whole or in part without prior
          notice to the User.<br />
          (1)In the event that the inspection or maintenance of the computer
          system pertaining to the Services is carried out periodically or in an
          emergency<br />
          (2)Case where the computer or communication line has been shut down
          due to an accident.<br />
          (3)The Service is no longer operational due to a fire, power failure,
          natural disaster, or other force majeure.<br />
          (4)For maintenance of the Services<br />
          (5)When it is judged that the continuation of the Service may
          seriously interfere with the user due to sabotage by a third party,
          etc.<br />
          (6)Otherwise, in the event we deem it necessary to suspend or suspend
        </li>
        <li>
          We have the right to discontinue this service in whole or in part at
          any time due to the occurrence of the event described in the preceding
          paragraph or our convenience.
        </li>
        <li>
          We shall not be liable for any disadvantage or damage incurred by you
          based on the measures taken by us under this article.
        </li>
      </ol>

      <h2>Article 21 (Processing after Termination of Agreement)</h2>
      <ol>
        <li>
          The user shall not be allowed to use the Services after the
          termination of the use agreement for any reason whatsoever
        </li>
        <li>
          We shall not be obliged to compensate for any damage caused to the
          user by deleting any data stored in the Services upon termination of
          the Use Agreement for any reason whatsoever.
        </li>
      </ol>

      <h2>Article 22 (Notice)</h2>
      <p>
        Notices concerning the Services and other notifications from us to the
        Users as set forth in these Terms and Conditions shall be made by means
        of display on the Services, electronic mail, or other methods as set
        forth by us. Notices shall become effective upon display or transmission
        from us.
      </p>

      <h2>Article 23 (Prohibition of Assignment of Rights and Obligations)</h2>
      <p>
        The User shall not cause any third party to succeed to the contractual
        status of the User Agreement or assign, transfer or pledge as security
        to any third party all or part of the rights and obligations under the
        User Agreement
      </p>

      <h2>Article 24 (Force Majeure)</h2>
      <p>
        In the event the performance of the Services is hindered by a natural
        disaster, enactment, revision or abolition of laws and regulations, or
        any other force majeure event, notwithstanding any provision of the User
        Agreement or any other provision, the Company shall not assume any
        responsibility whatsoever for any damage suffered by the User due to
        such force majeure.
      </p>

      <h2>Article 25 (Consultation)</h2>
      <p>
        In the event of any objection or doubt between the parties or any matter
        not stipulated herein arising, the parties hereto shall negotiate in
        good faith and resolve such matter amicably.
      </p>

      <h2>Section 26 (Governing Law and Jurisdiction)</h2>
      <p>
        The applicable law of Japan shall govern the matters concerning the
        contract of use, and the Tokyo District Court shall be the exclusive
        jurisdiction of the first instance.
      </p>

      <h2>Supplementary Provisions</h2>
      <p>This Agreement shall be effective as of January 1, 2021</p>

      <p class="right">More than</p>
    </div>
    <div v-else>
      <h1 class="sub">TERMS<br /><span class="ja">云服务条款</span></h1>

      <h2>第1条（本条款的目的）</h2>
      <p>
        本协议适用于里德实际地产有限公司（以下简称“本公司”）。）的在线服务“REAL
        ESTATE ZONE AI+MOVIE”（以下简称“本服务”。）来定义自定义外观。
      </p>

      <h2>第2条（术语定义）</h2>
      <p>
        在本条款中，下列术语的含义如下。<br />
        (1)使用合同:本公司与用户根据本协议签订的关于提供服务的协议<br />
        (2)用户:未通过会员注册获得ID而使用本服务的人员
      </p>

      <h2>第3条（本条款的适用）</h2>
      <p>
        本公司根据使用协议的内容提供服务，用户应根据使用协议和本公司规定的条款使用该服务。
      </p>

      <h2>第4条(本规约的变更)</h2>
      <ol>
        <li>
          本公司可以随时修改本条款，而无需事先获得用户的同意。本条款修改后的服务提供条款应遵守修改后的新使用条款。
        </li>
        <li>
          本公司在进行前项修改时，应将修改后的新使用条款的内容通知用户或在本服务上显示，至少有14天的预告期。但是，如果我们认为更改很小，不会对用户造成特别不利，我们将不通知您。
        </li>
      </ol>

      <h2>第5条（使用合同的接受）</h2>
      <ol>
        <li>
          用户接受本条款的内容，并按照本公司规定的方式使用本服务。当用户选择“同意”本协议时，本协议的内容即被用户接受，并被视为使用协议的成立。
        </li>
        <li>
          尽管有前款规定，但在下列情况下，本公司可以根据本公司的判断限制该用户的使用或解除使用合同。<br />
          (1)本服务的使用目的被怀疑为评价、分析或其他与本来目的不同的情况<br />
          (2)利用者或其代表，官员，反社会势力（指黑社会组织，黑社会成员等）。）或可能<br />
          (3)违反本章程的<br />
          (4)本公司有其他适当理由认为不适当的情况
        </li>
        <li>根据前项，本公司不因限制用户使用或解除使用合同而承担责任。</li>
      </ol>

      <h2>第6条（服务范围）</h2>
      <p>
        本公司将在本公司指定的条件下，由用户管理的终端设备（包括智能手机，移动电话，个人电脑等）。
        以下简称“终端设备”。）通过电信线路连接到我们指定的服务器，提供使用本服务的环境。
      </p>

      <h2>第7条(利用限制)</h2>
      <ol>
        <li>
          提供本服务的目的是为购买物业而使用，不得将本服务用于商业目的（例如，向第三方提供服务，无论是向第三方收费还是免费）。
        </li>
        <li>
          用户使用服务是通过从终端设备连接到我们指定的URL来实现的，不能通过下载或复制构成服务的软件本身来获得构成服务的软件。
        </li>
      </ol>

      <h2>第8条（本服务的变更）</h2>
      <p>
        为了增加或改进服务的功能，本公司可自行决定对服务进行规格或设计更改，操作系统或其他部分的增加或更改。但是，并不保证这些增加或更改将保持本服务在更改前的所有功能和性能。
      </p>

      <h2>第9条（服务级别）</h2>
      <p>
        本公司将努力提高本服务的服务水平。但不保证与本服务有关的质量，性能或服务水平。
      </p>

      <h2>第10条(电信线路)</h2>
      <p>
        从用户使用的终端设备连接到本服务的电信线路由用户自行负责并承担费用，本公司不承担任何责任。
      </p>

      <h2>第11条(数据管理)</h2>
      <ol>
        <li>
          对于与使用服务有关的输入，提供或传输的数据等，用户应自行负责维护必要的信息。
        </li>
        <li>
          如果用户使用的信息因提供服务的设备等发生故障而丢失，本公司将单独记录并保存一段时间，以恢复该信息，但不承担恢复义务。
        </li>
      </ol>

      <h2>第12条(个人信息的管理)</h2>
      <ol>
        <li>本服务可以由本公司利用AI获取用户的个人信息。</li>
        <li>
          如果输入到本服务中的数据包含个人信息，本公司将不用于提供本服务的目的以外的目的，并根据有关个人信息保护的法律和本公司个人信息保护政策（隐私政策），采取合理的安全管理措施，严格管理，防止丢失，销毁，篡改，泄露等危险。
        </li>
        <li>
          本公司应立即销毁因提供服务而不再需要的个人信息，并由本公司负责，不留任何副本。
        </li>
        <li>本条款的规定在使用合同终止后有效存续。</li>
      </ol>

      <h2>第13条（我们对信息的管理和使用）</h2>
      <ol>
        <li>
          为了改进服务，维护和管理服务等目的的统计调查，本公司将利用用户对服务的使用情况，屏幕和项目的使用频率等统计数字，或在统计调查所需的范围内分析这些信息，并进行二次加工和利用，用户同意进行此类统计调查和二次加工利用。
        </li>
        <li>
          我们将努力保持对用户输入的数据的机密，并在良好的管理人员的注意下对其进行管理。
        </li>
        <li>
          您同意，如果本公司根据法院或其他法定机关的命令或其他要求披露或提交与本服务有关的信息，本公司可根据这些命令披露或提交信息，并不反对此类披露或提交。
        </li>
      </ol>

      <h2>第14条(委托)</h2>
      <p>
        本公司可以在没有用户同意的情况下，将与提供本服务相关的全部或部分业务委托给第三方。但在这种情况下，本公司应负责管理委托方。
      </p>

      <h2>第15条(禁止行为)</h2>
      <p>
        用户在使用本服务时，不得采取以下行为。<br />
        (1)违法行为或可能违法行为<br />
        (2)违反社会公德的行为<br />
        (3)妨碍或可能妨碍其他用户使用的行为<br />
        (4)对构成本服务的硬件或软件的不正当访问行为，破解行为以及其他对设备等造成障碍的行为<br />
        (5)妨碍或可能妨碍本服务提供的行为<br />
        (6)分析构成本服务的软件、逆向工程以及其他获取源代码的行为<br />
        (7)冒充他人使用或试图使用的行为<br />
        (8)查看、更改、篡改或可能篡改其他用户数据的行为
      </p>

      <h2>第16条(知识产权)</h2>
      <p>
        构成服务的有形和无形结构，包括软件程序，数据库，图标，图像，文本，文档等相关文档。）的所有知识产权和其他权利，包括版权，均归本公司或授权给本公司的任何第三方所有。
      </p>

      <h2>第17条(自己责任的原则)</h2>
      <ol>
        <li>
          用户对使用本服务和在本服务内的任何行为（如注册，查看，删除，传输信息）及其后果承担全部责任。
        </li>
        <li>
          如果您因使用本服务而导致第三方因其责任而受到损害，或第三方提出索赔或其他索赔，您应负责处理和解决，并承担费用。
        </li>
        <li>如果用户因其故意或过失给本公司造成损失，用户应赔偿本公司损失。</li>
      </ol>

      <h2>第18条（保修限制）</h2>
      <ol>
        <li>我们保证服务在重要方面基本上正常提供。</li>
        <li>
          本公司不保证构成本服务的软件不存在缺陷或其他缺陷，也不保证本服务符合用户的特定使用目的。此外，本公司不保证在终端设备中使用或与其他软件等一起使用时，本服务的正常运行。
        </li>
        <li>
          如果发现服务存在重大缺陷，我们将努力纠正或消除服务缺陷，但我们的责任仅限于此，我们不保证服务不存在缺陷，也不保证服务达到一定的质量和性能。
        </li>
        <li>本条款提供了对本服务的唯一保证。</li>
      </ol>

      <h2>第19条(免责及损害赔偿的限制)</h2>
      <ol>
        <li>
          本公司仅在受条款和条件限制的情况下对服务负责。本公司对本章程各条款规定不保证的事项，不承担责任的事项，或用户责任的事项不承担任何责任。
        </li>
        <li>
          对于因服务而导致的用户业务机会损失，利润损失，数据丢失或损坏，无论合同责任，侵权责任或任何其他索赔原因，本公司均不承担任何责任。
        </li>
      </ol>

      <h2>第20条（本服务的停止和终止）</h2>
      <ol>
        <li>
          当出现下列情况之一时，本公司可在不事先通知用户的情况下，全部或部分停止或中断本服务的使用。<br />
          (1)定期或紧急进行与本服务相关的计算机系统的检查或维护工作；<br />
          (2)计算机、通信线路等因事故停机的<br />
          (3)因火灾、停电、天灾地变等不可抗力导致本服务无法运营的；<br />
          (4)维护本服务<br />
          (5)由于第三方的干扰行为等，本服务的继续被认为可能对用户造成重大影响的情况<br />
          (6)本公司认为需要暂停或中断的其他情形
        </li>
        <li>
          本公司有权根据前款规定的原因或本公司的情况随时取消本服务的一部分或全部。
        </li>
        <li>
          本公司对用户因本公司根据本条所采取的措施而造成的任何不利或损害不承担任何责任。
        </li>
      </ol>

      <h2>第21条(合同结束后的处理)</h2>
      <ol>
        <li>如果用户因任何原因而终止使用合同，则此后不得再使用本服务。</li>
        <li>
          如果本公司因任何原因而终止使用协议，本公司不承担因删除本服务中存储的所有数据而给用户造成的任何损失的赔偿义务。
        </li>
      </ol>

      <h2>第22条(通知)</h2>
      <p>
        本协议中规定的关于本服务的通知以及本公司向用户发出的通知，应在本服务中显示，通过电子邮件或本公司规定的其他方式发出。通知应在本公司的表示和发出后生效。
      </p>

      <h2>第23条（禁止权利和义务转让）</h2>
      <p>
        用户不得将使用协议的合同地位转让给第三方，或将其在使用协议中的全部或部分权利和义务转让给第三方，使第三方继承或提供担保。
      </p>

      <h2>第24条(不可抗力)</h2>
      <p>
        如因天灾，法律或法规的制定或废除或其他不可抗力而妨碍服务的履行，本公司不承担因该不可抗力给用户造成的任何损失的任何责任，尽管有使用合同或其他任何规定。
      </p>

      <h2>第25条(协商)</h2>
      <p>
        如双方对本章程的解释产生异议或疑义，或本章程未作规定的事项，应真诚协商，争取圆满解决。
      </p>

      <h2>第26条（适用法律和司法管辖）</h2>
      <p>
        有关使用合同的事项以日本法律为准据法，东京地方法院是第一审的专属协议管辖法院。
      </p>

      <h2>附则</h2>
      <p>本章程自2021年1月1日起实施。</p>

      <p class="right">以上</p>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    let route = this.$route;
    if (route.meta.title) {
      document.title = route.meta.title;
    }
  },
};
</script>

<style lang="scss" scoped>
.terms {
  margin: 0 auto;
  max-width: 1000px;
  width: 80%;
}

.terms h1 {
  margin-top: 150px;
}

.terms h2 {
  font-size: 110%;
  letter-spacing: 0.1em;
  line-height: 2;
  margin: 50px 0 15px;
}

.terms h2 + p {
  font-size: 90%;
  line-height: 2;
}

.terms ol {
  font-size: 90%;
  line-height: 2;
  margin-top: 15px;
  padding-left: 2em;
}

.terms .right {
  text-align: right;
}
</style>
