<template>
  <div id="main" class="form">
    <h1 class="sub">
      CONTACT<br /><span v-if="langIsJa()" class="ja">お問い合わせ</span>
    </h1>
    <form>
      <div id="input" v-show="!is_checking">
        <dl>
          <dt>
            {{ t("contact.name")
            }}<span class="required">{{ t("contact.required") }}</span>
          </dt>
          <dd>
            <input
              type="text"
              name="name"
              v-model="name"
              :placeholder="t('contact.name-sample')"
            />
          </dd>

          <div v-if="langIsJa()">
            <dt>フリガナ<span class="required">必須</span></dt>
            <dd>
              <input
                type="text"
                name="kana"
                v-model="kana"
                placeholder="ヤマダ タロウ"
              />
            </dd>
          </div>

          <dt>
            {{ t("contact.tel")
            }}<span class="required">{{ t("contact.required") }}</span>
          </dt>
          <dd>
            <input
              type="text"
              name="tel"
              v-model="tel"
              placeholder="090-0000-0000"
            />
          </dd>

          <dt>
            {{ t("contact.email")
            }}<span class="required">{{ t("contact.required") }}</span>
          </dt>
          <dd>
            <input
              type="text"
              name="email"
              v-model="email"
              placeholder="yamadataro@example.com"
            />
          </dd>

          <dt>{{ t("contact.property") }}</dt>
          <dd><input type="text" name="estate" v-model="estate" /></dd>

          <dt>
            {{ t("contact.content")
            }}<span class="required">{{ t("contact.required") }}</span>
          </dt>
          <dd><textarea name="content" v-model="content"></textarea></dd>
        </dl>
        <h2>{{ t("contact.sub") }}</h2>
        <dl>
          <dt>{{ t("contact.area") }}</dt>
          <dd>
            <input
              type="text"
              name="other_area"
              v-model="other_area"
              :placeholder="t('contact.area-sample')"
            />
          </dd>

          <dt>{{ t("contact.price") }}</dt>
          <dd>
            <input
              type="text"
              name="other_price"
              v-model="other_price"
              :placeholder="t('contact.price-sample')"
            />
          </dd>

          <dt>{{ t("contact.type") }}</dt>
          <dd>
            <input
              type="text"
              name="other_type"
              v-model="other_type"
              :placeholder="t('contact.type-sample')"
            />
          </dd>

          <dt>{{ t("contact.other") }}</dt>
          <dd><textarea name="other_free" v-model="other_free"></textarea></dd>
        </dl>
        <dl></dl>
        <a id="check" href="#" @click="handleCheckClick">{{
          t("contact.confirm")
        }}</a>
      </div>

      <div id="check_body" v-show="is_checking">
        <div id="send_data">
          <dl>
            <div class="checked_item">
              <dt>{{ t("contact.name") }}</dt>
              <dd>
                <p class="name">{{ name }}</p>
              </dd>
            </div>

            <div v-if="langIsJa()" class="checked_item">
              <dt>フリガナ</dt>
              <dd>
                <p class="kana">{{ kana }}</p>
              </dd>
            </div>

            <div class="checked_item">
              <dt>{{ t("contact.tel") }}</dt>
              <dd>
                <p class="tel">{{ tel }}</p>
              </dd>
            </div>

            <div class="checked_item">
              <dt>{{ t("contact.email") }}</dt>
              <dd>
                <p class="email">{{ email }}</p>
              </dd>
            </div>

            <div class="checked_item">
              <dt>{{ t("contact.property") }}</dt>
              <dd>
                <p class="estate">{{ estate }}</p>
              </dd>
            </div>

            <div class="checked_item">
              <dt>{{ t("contact.content") }}</dt>
              <dd>
                <p class="content">{{ content }}</p>
              </dd>
            </div>
          </dl>
          <h2>{{ t("contact.sub-confirm") }}</h2>
          <dl>
            <div class="checked_item">
              <dt>{{ t("contact.area") }}</dt>
              <dd>
                <p class="other_area">{{ other_area }}</p>
              </dd>
            </div>

            <div class="checked_item">
              <dt>{{ t("contact.price") }}</dt>
              <dd>
                <p class="other_price">{{ other_price }}</p>
              </dd>
            </div>

            <div class="checked_item">
              <dt>{{ t("contact.type") }}</dt>
              <dd>
                <p class="other_type">{{ other_type }}</p>
              </dd>
            </div>

            <div class="checked_item">
              <dt>{{ t("contact.other") }}</dt>
              <dd>
                <p class="other_free">{{ other_free }}</p>
              </dd>
            </div>
          </dl>
          <dl></dl>
        </div>
        <a href="#" id="edit" @click="handleEditClick">{{
          t("contact.modify")
        }}</a>
        <button type="button" id="submit" @click="handleSubmit">
          {{ t("contact.send") }}
        </button>
      </div>
    </form>
    <div class="other">
      <a
        href="https://www.lead-real.co.jp/contact/contact.php"
        target="_blank"
        >{{ t("contact.other-inquiries") }}</a
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CONSTANTS from "@/common/constants/constants";
const API_URL_PREFIX = CONSTANTS.API_URL_PREFIX;
export default {
  name: "Contact",
  data() {
    return {
      name: "",
      kana: "",
      tel: "",
      email: "",
      estate: "",
      content: "",
      other_area: "",
      other_price: "",
      other_type: "",
      other_free: "",
      is_checking: "",
    };
  },
  created() {
    this.is_checking = false;
    this.estate = this.$route.params.propertyName || "";
  },
  methods: {
    handleCheckClick: function (e) {
      if (this.checkForm()) {
        this.is_checking = true;
      } else {
        e.preventDefault();
        if (this.langIsJa()) {
          alert("必須入力項目をお確かめください。");
        } else if (this.langIsEn()) {
          alert("Please check the required items.");
        } else if (this.langIsZh()) {
          alert("请检查必填项。");
        }
      }
    },
    handleEditClick: function () {
      this.is_checking = false;
    },
    handleSubmit: function () {
      axios
        .post(API_URL_PREFIX + "/site_api/v1/contact", {
          name: this.name,
          kana: this.kana,
          tel: this.tel,
          email: this.email,
          estate: this.estate,
          content: this.content,
          other_request: {
            other_area: this.other_area,
            other_price: this.other_price,
            other_type: this.other_type,
            other_free: this.other_free,
          },
        })
        .then((response) => {
          console.log(response);
          this.$router.push({ name: "Thankyou" });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    checkForm: function () {
      let condition;
      if (this.langIsEn() || this.langIsZh()) {
        condition = this.name && this.tel && this.email && this.content;
      } else {
        condition =
          this.name && this.kana && this.tel && this.email && this.content;
      }
      return condition;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/styles/mixins";
.form {
  margin-top: 150px;

  h2 {
    font-size: 100%;
    letter-spacing: 0.1em;
    margin-top: 75px;
    text-align: center;
    text-indent: 0.1em;
  }

  dl {
    overflow: hidden;
    align-items: center;
    max-width: 800px;
    margin: 50px auto 0;
    width: 80%;
  }

  dt {
    border-top: 1px solid #eee;
    color: #999;
    float: left;
    line-height: 1.66;
    padding: 27px 0;
    width: 250px;

    @include screensize(small) {
      width: auto;
      float: none;
    }
  }

  dd {
    border-top: 1px solid #eee;
    padding: 17px 0 17px 250px;
    @include screensize(small) {
      border-top: none;
      padding: 17px 0;
    }
  }

  input,
  textarea {
    width: 100%;
    background: #fafafa;
    border: 0;
    line-height: 1.66;
    padding: 10px;
    outline: none;
    font-size: 16px;
  }

  textarea {
    height: 200px;
  }

  .required {
    background: #cc9c34;
    color: #fff;
    display: inline-block;
    font-size: 70%;
    font-weight: 700;
    margin: 0 0 3px 10px;
    padding: 0 5px;
    vertical-align: middle;
  }

  #check,
  #edit,
  #submit {
    background: #cc9c34;
    color: #fff;
    display: block;
    line-height: 50px;
    margin: 75px auto 0;
    padding: 10px 0;
    text-align: center;
    width: 90%;
    max-width: 500px;
  }

  #edit {
    background: transparent;
    color: #999;
    line-height: 20px;
    margin-top: 30px;
  }

  #submit {
    margin-top: 30px;
    border: none;
    outline: none;
    cursor: pointer;
  }

  #check_body {
    dd {
      line-height: 1.66;
      padding: 27px 0;
      width: 100%;
    }

    .checked_item {
    }
  }

  .other {
    font-size: 90%;
    margin-top: 150px;
    text-align: center;

    a {
      text-decoration: underline;
    }
  }
}
</style>
