import config from "../../../config/config.js";
import { SaleFlag } from "../constants/constants";

export const isSold = {
  methods: {
    isSold: function (item) {
      return item.sale_flag === SaleFlag.SOLD;
    },
  },
};

export const isSigned = {
  methods: {
    isSigned: function (property) {
      return (
        property.sale_flag !== SaleFlag.IN_SALE &&
        property.sale_flag !== SaleFlag.SOLD
      );
    },
  },
};

export const getGrobalIp = {
  methods: {
    getGrobalIp: function () {
      const S = "stun.l.google.com:19302";

      return new Promise((resolve) => {
        const pc = new RTCPeerConnection({
          iceServers: [
            {
              urls: ["stun:" + S],
            },
          ],
        });

        const rslt = [];
        pc.onicecandidate = (e) => {
          if (!e.candidate) {
            return resolve(rslt);
          }
          const [ip, , , type] = e.candidate.candidate.split(" ", 8).slice(4);
          if (type == "srflx") {
            rslt.push(ip);
          }
        };

        pc.onnegotiationneeded = async () => {
          await pc.setLocalDescription(await pc.createOffer());
        };

        pc.createDataChannel("");
      });
    },
  },
};

export const ipAllowedCheck = {
  methods: {
    ipAllowedCheck: function (ip) {
      return config.AllowedUrls.includes(String(ip));
    },
  },
};

export const urlLinkSettingMixin = {
  data() {
    return {
      privacy_policy_url: null,
      contact_url: null,
    };
  },
  mounted() {
    this.setContactURL();
    this.setPrivacyPolicyURL();
  },
  updated() {
    this.setContactURL();
    this.setPrivacyPolicyURL();
  },
  methods: {
    setPrivacyPolicyURL: function () {
      // mount時とLangSetting.vueのボタンをクリックしたときに子要素から呼んでいる。
      if (this.langIsEn()) {
        this.privacy_policy_url = "https://www.lead-real.co.jp/en/privacy/";
      } else if (this.langIsZh()) {
        this.privacy_policy_url = "https://www.lead-real.co.jp/zh/privacy/";
      } else {
        this.privacy_policy_url = "https://www.lead-real.co.jp/privacy/";
      }
    },
    setContactURL: function () {
      // mount時とLangSetting.vueのボタンをクリックしたときに子要素から呼んでいる。
      if (this.langIsEn()) {
        this.contact_url = "https://www.lead-real.co.jp/en/contact/contact.php";
      } else if (this.langIsZh()) {
        this.contact_url = "https://www.lead-real.co.jp/zh/contact/contact.php";
      } else {
        this.contact_url = "https://www.lead-real.co.jp/contact/contact.php";
      }
    },
  },
};
