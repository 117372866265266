export default {
  API_URL_PREFIX: process.env.VUE_APP_API_URL_PREFIX,
  SRC_URL_PREFIX: process.env.VUE_APP_SRC_URL_PREFIX,
  GOOGLE_MAP_API: process.env.VUE_APP_GOOGLE_MAP_API,
  GEOCODING_API: process.env.VUE_APP_GEOCODING_API_KEY,
};

export const SaleFlag = {
  SIGNED: "0",
  IN_SALE: "1",
  SOLD: "2",
};
