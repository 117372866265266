<template>
  <div class="ai">
    <div class="header-curtain" @click="onHeaderClickFunc">
      <span class="float-chatbot-header-title">Ｌｅａｄ ＡＩＴａｌｋ</span>
      <BotHeaderIcon />
    </div>
    <div class="iframe-container">
      <iframe
        id="bot-frame"
        :src="
          SRC_URL_PREFIX +
          '/chatbot/?propid=' +
          propId +
          '&botLang=' +
          initialLang +
          '&siteLang=' +
          initialLang +
          '&propertyType=' +
          propertyType
        "
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
import CONSTANTS from "@/common/constants/constants";
import { BotHeaderIcon } from "@/components";

export default {
  name: "ChatBot",
  components: {
    BotHeaderIcon,
  },
  data() {
    return {
      SRC_URL_PREFIX: CONSTANTS.SRC_URL_PREFIX,
      initialLang: this.langState(),
    };
  },

  props: {
    propId: Number,
    propertyType: String,
    onHeaderClickFunc: Function,
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/styles/mixins";
@import "@/common/styles/variables";

.ai {
  background: transparent;
  color: #fff;
  height: 80%;
  max-height: 586px;
  position: fixed;
  width: $chatBotFixedWidth;
  bottom: 0;
  right: 32px;
  @include screensize(small) {
    width: auto;
    left: 8px;
    right: 8px;
  }
  z-index: 100;

  .iframe-container {
    height: auto;
    left: 0;
    top: 56px;
    bottom: 0;
    position: absolute;
    width: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .header-curtain {
    background-color: #f4f8f2;
    width: 100%;
    height: 56px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;

    .float-chatbot-header-title {
      margin: 0 10px;
      font-size: 16px;
      line-height: 56px;
      font-weight: inherit;
      color: #000;
    }
  }
}
</style>
