import Vue from "vue";
import Vuex from "vuex";
import { LANG_JA } from "./states";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: LANG_JA,
  },
  plugins: [createPersistedState({ paths: ["lang"] })],
  mutations: {
    setLang(state, lang) {
      state.lang = lang;
    },
  },
  actions: {},
  modules: {},
});
