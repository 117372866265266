import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Single from "../views/Single.vue";
import About from "../views/About.vue";
import Archive from "../views/Archive.vue";
import Contact from "../views/Contact.vue";
import Terms from "../views/Terms.vue";
import Privacy from "../views/Privacy.vue";
import Thankyou from "../views/Thankyou.vue";
import NotFound from "../views/NotFound.vue";
import Admin from "../views/admin/Admin.vue";
import Access from "../views/admin/Access.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/single/:propId(\\d+)",
    name: "Single",
    component: Single,
  },
  {
    path: "/archive",
    name: "Archive",
    component: Archive,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/contact/:propertyName?",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/thankyou",
    name: "Thankyou",
    component: Thankyou,
  },
  {
    path: "/:pathMatch(.*)",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/single/:propId",
    name: "SingleNotFound",
    component: NotFound,
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    children: [
      {
        path: "access",
        name: "admin-access",
        component: Access,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 300);
      });
    }
    return { left: 0, top: 0 };
  },
});

export default router;
