<template>
  <label class="lang-btn" :class="{ checked }">
    <span>{{ label }}</span>
    <input
      type="radio"
      :value="lang"
      :checked="checked"
      @change="$emit('change', $event.target.value)"
    />
  </label>
</template>

<script>
import { LANG_JA, LANG_EN, LANG_ZH } from "@/store/states.js";

export default {
  model: {
    prop: "currentLang",
    event: "change",
  },

  props: {
    lang: String,
    currentLang: String,
  },

  data() {
    return {
      labels: {
        [LANG_JA]: "JA",
        [LANG_EN]: "EN",
        [LANG_ZH]: "ZH",
      },
    };
  },

  computed: {
    label() {
      return this.labels[this.lang];
    },
    checked() {
      return this.currentLang === this.lang;
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-btn {
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &.checked {
    font-weight: bold;
  }

  input {
    display: none;
  }
}
</style>
