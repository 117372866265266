<template>
  <div id="notfound">
    <!--This templates was made by Colorlib (https://colorlib.com)-->
    <div class="notfound-container">
      <div class="notfound">
        <h1>404</h1>
        <h2>Oops! Page Not Be Found</h2>
        <p>Sorry but the page you are looking for does not exist.</p>
        <router-link :to="{ name: 'Home' }">Back to homepage</router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/common/styles/mixins";
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#notfound {
  display: flex;
  height: 100vh;

  justify-content: center;
  align-items: center;
}

.notfound {
  max-width: 560px;
  padding-left: 160px;
  line-height: 1.1;
  @include screensize(small) {
    padding-left: 0;
  }
}

.notfound h1 {
  font-size: 65px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #151723;
  text-transform: uppercase;
}

.notfound h2 {
  font-size: 21px;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  color: #151723;
}

.notfound p {
  color: #999fa5;
  font-weight: 400;
}

.notfound a {
  display: inline-block;
  font-weight: 700;
  border-radius: 40px;
  text-decoration: none;
  color: #388dbc;
}
</style>
