//
本当はchatbot側で作りたいけど、ChatBotコンポーネントと同列でposition:fixedにしたいので、
// website側で作る。 // スタイルはchatbot側と合わせる。
<template>
  <div class="float-chatbot-header" @click="$emit('click', $event)">
    <h2 class="float-chatbot-header-title">{{ title }}</h2>
    <BotHeaderIcon />
  </div>
</template>

<script>
import BotHeaderIcon from "@/components/BotHeaderIcon.vue";

export default {
  emits: ["click"],
  components: {
    BotHeaderIcon,
  },
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/styles/mixins";
@import "@/common/styles/variables";
$headerBgColor: #f4f8f2;
$headerFontColor: #000;
$headerFontSize: 16px;

.float-chatbot-header {
  align-items: center;
  background: $headerBgColor;
  color: $headerFontColor;
  display: flex;
  fill: $headerFontColor;
  height: 56px;
  width: $chatBotFixedWidth;
  justify-content: space-between;
  margin: 0 8px;
  position: fixed;
  bottom: 0;
  right: 32px;
  @include screensize(small) {
    width: auto;
    left: 0;
    right: 0;
  }
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  z-index: 100;

  .float-chatbot-header-title {
    margin: 0 10px;
    font-size: $headerFontSize;
    font-weight: inherit;
  }
}
</style>
