<template>
  <div id="app">
    <header id="header">
      <div class="container">
        <div class="title-hamburger">
          <div class="title">
            <router-link :to="{ name: 'Home' }">
              <img src="./assets/img/title_1.png" alt="ESTATE ZONE AI+" />
            </router-link>
          </div>
          <div v-on:click="toggleShowHeaderNav" class="hamburger-button">
            <MenuIcon />
          </div>
        </div>
        <div :class="{ nav: true, showHeaderNav: showHeaderNav }">
          <router-link :to="{ name: 'Home' }" class="nav-item">{{
            t("header.toppage")
          }}</router-link>
          <router-link :to="{ name: 'About' }" class="nav-item">{{
            t("header.about")
          }}</router-link>
          <router-link :to="{ name: 'Archive' }" class="nav-item">{{
            t("header.properties")
          }}</router-link>
          <a :href="contact_url" class="no-lineheight nav-item" target="_blank">
            <SquareButtonYellow v-if="langIsEn()" text="CONTACT US" />
            <SquareButtonRed v-else-if="langIsZh()" text="咨询" />
            <SquareButton v-else text="お問い合わせ"></SquareButton>
          </a>
          <LangSetting class="nav-item" />
        </div>
      </div>
    </header>

    <router-view :key="$route.fullPath" />

    <footer id="footer">
      <div class="sitemap">
        <router-link :to="{ name: 'Home' }">{{
          t("footer.toppage")
        }}</router-link>
        <router-link :to="{ name: 'About' }">{{
          t("footer.about")
        }}</router-link>
        <router-link :to="{ name: 'Archive' }">{{
          t("footer.properties")
        }}</router-link>
        <a :href="contact_url" target="_blank">{{ t("footer.contact") }}</a>
      </div>
      <div class="sitemap_sub">
        <a href="https://www.lead-real.co.jp/company/" target="_blank">{{
          t("footer.company")
        }}</a>
        <router-link :to="{ name: 'Terms' }">{{
          t("footer.terms")
        }}</router-link>
        <a :href="privacy_policy_url" target="_blank">{{
          t("footer.privacy")
        }}</a>
      </div>

      <div class="copyright">&copy; LEAD-REAL ESTATE CO. LTD.</div>
    </footer>
    <modal :urls="urls" v-if="showModal" @close="showModal = false"></modal>
  </div>
</template>

<script>
import {
  SquareButton,
  SquareButtonYellow,
  SquareButtonRed,
  LangSetting,
  Modal,
} from "@/components";
import MenuIcon from "@/common/icons/menu-icon";
import { urlLinkSettingMixin } from "@/common/mixins/mixins.js";
export default {
  name: "app",
  mixins: [urlLinkSettingMixin],
  data() {
    return {
      showModal: false,
      showHeaderNav: false,
      urls: null,
    };
  },
  components: {
    SquareButton,
    SquareButtonYellow,
    SquareButtonRed,
    LangSetting,
    Modal,
    MenuIcon,
  },
  mounted() {
    window.addEventListener("message", this.receiveMessage);
  },
  methods: {
    receiveMessage(event) {
      var modal_url = event.data;
      if (modal_url["urls"]) {
        this.urls = modal_url["urls"];
        this.showModal = true;
      }
    },
    setPrivacyPolicyURL: function () {
      // mount時とLangSetting.vueのボタンをクリックしたときに子要素から呼んでいる。
      if (this.langIsEn()) {
        this.privacy_policy_url = "https://www.lead-real.co.jp/en/privacy/";
      } else if (this.langIsZh()) {
        this.privacy_policy_url = "https://www.lead-real.co.jp/zh/privacy/";
      } else {
        this.privacy_policy_url = "https://www.lead-real.co.jp/privacy/";
      }
    },
    setContactURL: function () {
      // mount時とLangSetting.vueのボタンをクリックしたときに子要素から呼んでいる。
      if (this.langIsEn()) {
        this.contact_url = "https://www.lead-real.co.jp/en/contact/contact.php";
      } else if (this.langIsZh()) {
        this.contact_url = "https://www.lead-real.co.jp/zh/contact/contact.php";
      } else {
        this.contact_url = "https://www.lead-real.co.jp/contact/contact.php";
      }
    },
    toggleShowHeaderNav: function () {
      this.showHeaderNav = !this.showHeaderNav;
    },
  },
  watch: {
    showModal: function (newVal) {
      if (newVal === true) {
        document.body.classList.add("modal-showing");
      } else {
        document.body.classList.remove("modal-showing");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/common/styles/mixins";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=EB+Garamond&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body.modal-showing {
  overflow-y: hidden;
}

#app {
  background: #fff;
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

a {
  color: #222;
  text-decoration: none;
  transition: opacity 0.5s;
}

a:hover {
  opacity: 0.7;
}

a img {
  border: 0;
}

.serif {
  font-family: "EB Garamond", serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

img {
  max-width: 100%;
}

#header {
  .container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    max-width: 1300px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    flex-direction: row;
    @include screensize(small, medium) {
      flex-direction: column;
    }

    .title-hamburger {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include screensize(small, medium) {
        width: 100%;
      }

      .title {
        margin-left: 40px;
        margin-right: 40px;
        a {
          display: block;
        }

        img {
          display: block;
          height: 26px;
          object-fit: contain;
        }
      }

      .hamburger-button {
        margin-right: 60px;
        svg {
          height: 100%;
          display: inline-block;
        }
        @include screensize(large) {
          display: none;
        }
      }
    }

    .nav {
      display: none;
      flex-direction: column;
      @include screensize(large) {
        display: flex;
        flex-direction: row;
      }
      margin-right: 20px;
      align-items: center;

      &.showHeaderNav {
        display: flex;
        @include screensize(small, medium) {
          margin-top: 40px;
        }
      }

      .nav-item {
        @include screensize(large) {
          margin-bottom: 0;
        }
        font-size: 85%;
        font-weight: 700;
        line-height: 26px;
        margin: 0 0 20px 30px;
      }
    }
  }
}

#footer {
  background: #000;
  color: #fff;
  line-height: 1.5;
  margin-top: 150px;
  padding: 100px 0;

  a {
    color: #fff;
    display: inline-block;
    margin: 0 20px 0 0;
  }

  div {
    margin: 0 auto;
    width: 90%;
    max-width: 1200px;
  }

  .sitemap {
    font-size: 80%;
  }

  .sitemap_sub {
    font-size: 70%;
    margin: 25px auto 0;
  }

  .copyright {
    font-size: 60%;
    margin: 25px auto 0;
  }
}

.sub {
  line-height: 1.2;
  text-align: center;
  text-indent: 0.2em;

  .sub-main-ja {
    font-size: 150%;
    letter-spacing: 0.2em;
    margin-bottom: 8px;
  }

  .sub-main-en {
    font-size: 1.1em;
    font-weight: normal;
    letter-spacing: 0.5em;
  }

  .sub-main-zh {
    font-size: 1.1em;
    font-weight: normal;
    letter-spacing: 0.4em;
    margin: 8px;
  }

  .sub-sub {
    color: #666;
    font-size: 90%;
    font-weight: 400;
    letter-spacing: 0.2em;
    text-indent: 0.2em;
    text-align: center;

    &.zh {
      color: #efc96b;
      font-family: "Montserrat", sans-serif;
    }
  }
}

.list {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 100px;
  width: 90%;

  ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    list-style: none;
  }

  li {
    border-left: 1px solid #eee;
    margin-top: 50px;
    padding: 0 35px;
    text-align: center;

    a {
      display: block;

      span {
        line-height: 1.5;
      }

      .thumb {
        display: block;
        margin-bottom: 18px;

        img {
          display: block;
        }
      }

      .cat {
        color: #666;
        display: inline-block;
        font-size: 70%;
        border: 1px solid #999;
        letter-spacing: 0.2em;
        line-height: 24px;
        margin-right: 8px;
        text-indent: 0.1em;
        padding: 0 8px;
        vertical-align: middle;
      }

      .local {
        font-size: 80%;
        letter-spacing: 0.1em;
        line-height: 26px;
        text-indent: 0.1em;
      }

      .price {
        display: block;
        font-size: 160%;
        font-weight: 700;
        margin: 10px 0 5px;
      }

      .access {
        color: #666;
        display: block;
        font-size: 75%;

        strong {
          color: #222;
          font-size: 115%;
        }
      }
    }

    a.disabled {
      pointer-events: none;
    }
  }

  li.sold {
    .thumb {
      position: relative;
    }

    .thumb::after {
      content: "";
      background: url(./assets/img/sold.png) 0 0 no-repeat;
      background-size: contain;
      display: block;
      position: absolute;
      top: -6px;
      right: 6px;
      width: 41px;
      height: 105px;
    }
  }
}

.col2 {
  li {
    width: 50%;

    @include screensize(small, medium) {
      width: 100%;
      border-right: 1px solid #eee;
    }
  }

  li:nth-child(2n + 2) {
    border-right: 1px solid #eee;
  }
}

.col3 {
  li {
    width: 33.3%;

    a .price {
      font-size: 135%;
    }

    @include screensize(small, medium) {
      width: 100%;
      border-right: 1px solid #eee;
    }
  }

  li:nth-child(3n + 3) {
    border-right: 1px solid #eee;
  }
}

.col4 {
  li {
    width: 25%;
    padding: 0 25px;
    @include screensize(medium) {
      width: 50%;
    }
    @include screensize(small) {
      width: 100%;
      border-right: 1px solid #eee;
    }

    a {
      .cat {
        margin: 0 0 3px 0;
      }

      .local {
        display: block;
      }

      .price {
        font-size: 115%;
        margin: 6px 0 8px;
      }
    }
  }

  li:nth-child(2n + 2) {
    @include screensize(medium) {
      border-right: 1px solid #eee;
    }
  }
  li:nth-child(4n + 4) {
    border-right: 1px solid #eee;
  }
}

.no-lineheight {
  line-height: 0 !important;
}
</style>
