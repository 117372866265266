import store from "../store";
import data from "./translate.json";

export default {
  methods: {
    t: (arg) => {
      const keys = arg.split(".");

      let text;
      if (keys.length === 1) {
        text = data[keys[0]];
      } else {
        text = keys.reduce((acm, crnt, i) => {
          if (i === 1) {
            return data[acm][crnt];
          } else {
            return acm[crnt];
          }
        });
      }

      return text[store.state.lang];
    },
    langState: () => {
      return store.state.lang;
    },
    langIsJa: () => {
      return store.state.lang === "ja";
    },
    langIsEn: () => {
      return store.state.lang === "en";
    },
    langIsZh: () => {
      return store.state.lang === "zh";
    },
  },
};
