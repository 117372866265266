<template>
  <div :class="{ sold: isSold(property), signed: isSigned(property) }">
    <span class="thumb">
      <img :src="imageSrc" alt="thumb" />
    </span>
    <div class="sold-text">販売済み</div>
    <span class="cat">{{ property.classification }}</span>
    <span class="local">{{ location }}</span>
    <span class="price">{{ property.property_price }}万円</span>
    <span class="access">
      <strong>{{ property.access_number }}</strong> {{ t("archive.click") }}
    </span>
  </div>
</template>

<script>
import { isSigned, isSold } from "@/common/mixins/mixins.js";

export default {
  name: "Property",
  mixins: [isSigned, isSold],
  props: {
    property: Object,
  },
  computed: {
    location: function () {
      return this.sold
        ? this.removeChome(this.property.property_location)
        : this.property.property_location;
    },
    imageSrc() {
      if (!this.property.property_photo_link) {
        return "https://i.vimeocdn.com/video/814949724_600";
      }
      if (!this.property.property_photo_link.includes("_600")) {
        const link = this.property.property_photo_link.split("_");
        const photo_link = link[0];
        return photo_link + "_600";
      } else {
        return this.property.property_photo_link;
      }
    },
  },
  methods: {
    removeChome: function (location) {
      const re =
        /([二三四五六七八九]?[一十]?([一二三四五六七八九十〇]|[1234567890]*)丁目)|([1234567890]*-)/;
      const match = location.match(re);
      return location.substring(0, match.index);
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  line-height: 1.5;
}

.thumb {
  display: block;
  margin-bottom: 18px;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.cat {
  color: #666;
  display: inline-block;
  font-size: 70%;
  border: 1px solid #999;
  letter-spacing: 0.2em;
  line-height: 24px;
  margin-right: 8px;
  text-indent: 0.1em;
  padding: 0 8px;
  vertical-align: middle;
}

.local {
  font-size: 80%;
  letter-spacing: 0.1em;
  line-height: 26px;
  text-indent: 0.1em;
}

.price {
  display: block;
  font-size: 160%;
  font-weight: 700;
  margin: 10px 0 5px;
}

.access {
  color: #666;
  display: block;
  font-size: 75%;

  strong {
    color: #222;
    font-size: 115%;
  }
}

.sold-text {
  display: none;
  margin: 10px 0;
  color: red;
}

.sold,
.signed {
  .price {
    background-image: linear-gradient(#fe3464, #fe3464);
    background-position: 50%;
    background-size: 55% 1px;
    background-repeat: no-repeat;
  }
}

.signed {
  .thumb {
    position: relative;
  }

  .thumb::after {
    content: "";
    background: url("~@/assets/img/sold.png") 0 0 no-repeat;
    background-size: contain;
    display: block;
    position: absolute;
    top: -6px;
    right: 6px;
    width: 41px;
    height: 105px;
  }
}

.sold {
  .sold-text {
    display: block;
  }
}
</style>
