<template>
  <VimeoPlayer v-if="urlIsVimeo" :url="url" :lang="lang" />
  <div v-else class="movie">
    <iframe
      :src="url"
      width="630"
      height="360"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    />
  </div>
</template>

<script>
import VimeoPlayer from "./VimeoPlayer.vue";
export default {
  components: {
    VimeoPlayer,
  },
  props: {
    url: {
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },

  computed: {
    urlIsVimeo() {
      if (!this.url) {
        return false;
      }
      const url = new URL(this.url);
      const hostname = url.hostname;
      return hostname.includes("vimeo");
    },
  },
};
</script>

<style lang="scss" scoped>
.movie {
  height: 100%;
  background: #000;
  max-width: 960px;
  top: 20px;

  iframe {
    height: 100%;
    width: 100%;
  }
}
</style>
