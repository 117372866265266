<template>
  <div id="main" class="privacy">
    <h1 class="sub">
      PRIVACY POLICY<br /><span class="ja">プライバシーポリシー</span>
    </h1>

    <h2>１．個人情報の定義</h2>
    <p>
      個人情報とは、ご利用者様個人に関する情報であって、氏名、メールアドレス、生年月日その他の記述等により当該ご利用者様を識別できるものをいいます。
      ご利用者様の個人情報は当社が提供するオンラインサービス「REAL ESTATE ZONE
      AI＋MOVIE」（以下「本サービス」といいます。）の運営元である
      株式会社リード・リアルエステート（以下「当社」といいます。）が責任を持って管理します。
    </p>

    <h2>２．個人情報の利用目的</h2>
    <p>個人情報の利用目的は以下のとおりです。</p>
    <ul>
      <li>
        本サービスに関する契約のお申し込み、ご登録情報変更、ご案内及び確認手続のため
      </li>
      <li>
        アンケート、キャンペーン、メールマガジン、新サービス、ダイレクトメール、物件情報等お客様に有用と思われる情報の告知、お電話によるご紹介等、本サービスに関する各種ご提案のため
      </li>
      <li>本サービスの実施に必要な本人確認、各種通知等の事務処理のため</li>
      <li>ご利用者様からのお問い合わせ、申込み等へのご返信、ご対応のため</li>
      <li>
        ご提供いただいた個人情報を元に、個人として特定できない範囲において統計情報として集計し、ご利用者様に有用なサービス開発の参考資料とするため
      </li>
      <li>
        本サービスの利用状況の把握、閲覧履歴、分析及びサービスの品質向上のため
      </li>
      <li>本サービスの維持運営、改善のため</li>
      <li>
        本サービスの障害情報、メンテナンス情報等技術的なサポートに関する情報のご提供のため
      </li>
      <li>セキュリティ確保、事故発生時の調査、連絡のため</li>
      <li>
        本サービスの提供に当たり利用者から予め同意を得ている場合に、個人情報を第三者に提供するため
      </li>
      <li>
        その他各サービスの提供に当たり利用者から同意を頂いた利用目的のため
      </li>
    </ul>

    <h2>３．個人情報の変更等</h2>
    <p>
      本サービスのご利用者様は、本サービスにおいて登録した個人情報をいつでも変更・追加・削除することができます。
    </p>

    <h2>４．第三者への開示</h2>
    <p>
      利用者の個人情報について、ご本人の同意を得ずに第三者に開示することは、原則として致しません。ただし、以下のいずれかにあてはまる場合には、当社は利用者の皆様が登録した情報、利用履歴、メッセージ・書き込みの内容などの確認を行い、必要な範囲でこれらの情報を第三者に開示することがあります。なお、当社が行う合併、会社分割、事業譲渡等により、本サービスにかかる事業を第三者に承継させる場合には、利用者の個人情報の全部又は一部についても当該第三者に承継することがあります。
    </p>
    <ul>
      <li>法令に基づく場合</li>
      <li>
        生命、身体又は財産の保護のために必要がある場合であって、ご利用者様の同意を得ることが困難であるとき
      </li>
      <li>
        公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、ご利用者様の同意を得ることが困難であるとき
      </li>
      <li>
        裁判所、警察、その他の司法もしくは行政機関、またはこれらに準ずる者から法律に基づく照会があった場合
      </li>
      <li>
        本サービスを実施するために業務を委託している業者に対して、利用目的達成のために開示・提供する場合
      </li>
    </ul>

    <h2>５．免責</h2>
    <p>
      以下の場合は、第三者による個人情報の取得に関し、当社は何らの責任を負いません。
    </p>
    <ul>
      <li>
        ご利用者様自らが本サービス上の機能または別の手段を用いて他の者に個人情報を明らかにした場合
      </li>
      <li>
        ご利用者様による書き込みまたは第三者が入力した情報により、期せずして本人が特定できてしまった場合
      </li>
      <li>
        ご利用者様以外の第三者が個人を識別できる情報（ID・パスワード等）を入手した場合
      </li>
    </ul>

    <h2>６．cookie（クッキー）の使用について</h2>
    <p>
      当社は、会員の皆様によりよいサービスを提供するため、cookie（クッキー：サーバーコンピュータからユーザーのブラウザに送信され、ユーザーが使用しているコンピュータのハードディスクに蓄積される情報です。）を使用することがありますが、これにより個人を特定できる情報の収集を行えるものではなく、お客様のプライバシーを侵害することはございません。<br />
      また、cookieの受け入れを希望されない場合は、ブラウザの設定で変更することができます。この場合、本サービスで利用できる機能が制限されることがあります。
    </p>

    <h2>７．アクセスログについて</h2>
    <p>
      本サービスでは、アクセスされた方の情報をアクセスログという形で記録しています。<br />
      アクセスログは、アクセスされた方のIPアドレス、ホスト名、ご利用のブラウザ・OSの種類、アクセス日時などの情報が含まれます。<br />
      これらのアクセスログはサービスの保守管理や利用状況に関する統計分析のために活用されますが、それ以外の目的で利用することはありません。
    </p>

    <h2>８．お問い合わせ窓口</h2>
    <p>
      本サービスの個人情報の取り扱いに関するご意見・ご質問はこちらから<br />
      <br />
      窓口：株式会社リード・リアルエステート 情報システム部<br />
      住所：東京都渋谷区南平台町16-11 MFPR渋谷南平台ビル6F<br />
      TEL： 03-5784-5127<br />
      FAX：03-5784-5128
    </p>

    <h2>９．プライバシーポリシーの変更</h2>
    <p>
      当社は、プライバシーポリシーを、個人情報の保護を図るため及び法令等の変更に対応するために、必要に応じて変更することがあります。
    </p>
  </div>
</template>

<style lang="scss" scoped>
.privacy {
  margin: 0 auto;
  max-width: 1000px;
  width: 80%;
}

.privacy h1 {
  margin-top: 150px;
}

.privacy h2 {
  font-size: 110%;
  letter-spacing: 0.1em;
  line-height: 2;
  margin: 50px 0 15px;
}

.privacy h2 + p {
  font-size: 90%;
  line-height: 2;
}

.privacy ul {
  font-size: 90%;
  line-height: 2;
  margin-top: 15px;
  padding-left: 2em;
}
</style>
