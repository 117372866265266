<template>
  <div :class="outlineClass">
    <div
      class="outline-upper"
      :class="{ sold: isSold(property), signed: isSigned(property) }"
    >
      <span class="price">
        {{ property.price }}
        <span class="jpy">JPY</span>
      </span>
      <a
        v-scroll-to="'#contact'"
        class="no-lineheight nav-item"
        target="_blank"
      >
        <SquareButtonEnYellow
          v-if="langIsEn()"
          text="CONTACT US"
          height="45px"
          width="160px"
        />
        <SquareButtonRed v-else text="咨询" height="45px" width="160px" />
      </a>
    </div>

    <div v-if="langIsEn()" class="metrix">
      <SquareButtonEn :text="metrix" height="35px" />
    </div>
    <div v-else class="counter">
      <div class="count">
        {{ metrix }}
      </div>
    </div>
  </div>
</template>

<script>
import { isSigned, isSold } from "@/common/mixins/mixins.js";
import {
  SquareButtonEn,
  SquareButtonEnYellow,
  SquareButtonRed,
} from "@/components";
import { urlLinkSettingMixin } from "@/common/mixins/mixins.js";
export default {
  mixins: [urlLinkSettingMixin, isSigned, isSold],
  components: {
    SquareButtonEn,
    SquareButtonEnYellow,
    SquareButtonRed,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
    views: {
      type: Number,
    },
  },
  computed: {
    outlineClass() {
      return "outline-" + this.$store.state.lang;
    },
    metrix() {
      return `${this.views} ${this.t("single.views")}  /  ${
        this.property.access_number
      } ${this.t("single.click")}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/styles/mixins";

.outline-en {
  .outline-upper {
    display: flex;
    justify-content: space-between;
    @include screensize(small) {
      flex-direction: column;
    }

    .price {
      font-size: 2.8rem;
      margin: auto 0;
      letter-spacing: 0.1em;
      @include screensize(small) {
        margin-bottom: 1rem;
      }
      .jpy {
        font-size: 0.6em;
      }
    }
  }

  .metrix {
    margin-top: 30px;
  }
}

.outline-zh {
  .outline-upper {
    display: flex;
    justify-content: space-between;
    .price {
      font-size: 2.8rem;
      margin: auto 0;
      letter-spacing: 0.1em;

      .jpy {
        font-size: 0.6em;
      }
    }
  }

  .metrix {
    margin-top: 30px;
  }
  .counter {
    display: block;
    height: 34px;
    width: 254px;
    margin-top: 30px;
    margin-bottom: 10px;
    background: #333;

    .count {
      color: #fff;
      font-size: 100%;
      text-indent: 0.2em;
      line-height: 34px;
      margin: auto;
      width: 210px;
    }

    strong {
      color: #222;
      font-size: 115%;
    }
  }
}

.sold,
.signed {
  .price {
    background-image: linear-gradient(#fe3464, #fe3464);
    background-position: 0 60%;
    background-size: 100% 1px;
    background-repeat: repeat-x;
    text-decoration: none;
    display: inline-block;
  }
}
</style>
