<template>
  <div
    class="outline-ja"
    :class="{ sold: isSold(property), signed: isSigned(property) }"
  >
    <span class="counter">
      <strong>{{ views }}</strong>
      再生／
      <strong>{{ property.access_number }}</strong>
      アクセス
    </span>
    <span class="cat">{{ property.classification }}</span>
    <span class="local">{{ property.property_location }}</span>
    <span class="price">{{ property.property_price }}万円</span>
    <a v-scroll-to="'#contact'">お問い合わせ</a>
  </div>
</template>

<script>
import { isSigned, isSold } from "@/common/mixins/mixins.js";

export default {
  mixins: [isSigned, isSold],
  props: {
    property: {
      type: Object,
      required: true,
    },
    views: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.outline-ja {
  .counter {
    color: #666;
    display: block;
    font-size: 85%;
    margin-bottom: 25px;
    text-indent: 0.2em;

    strong {
      color: #222;
      font-size: 115%;
    }
  }

  .cat {
    color: #666;
    display: inline-block;
    font-size: 75%;
    border: 1px solid #999;
    letter-spacing: 0.1em;
    line-height: 30px;
    margin-right: 8px;
    text-indent: 0.2em;
    padding: 0 10px;
  }

  .local {
    display: inline-block;
    font-size: 90%;
    letter-spacing: 0.1em;
    line-height: 32px;
  }

  .price {
    display: block;
    font-size: 260%;
    font-weight: 700;
    margin-top: 15px;
  }

  a {
    background: #cc9c34;
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.1em;
    line-height: 45px;
    width: 160px;
    text-align: center;
    text-indent: 0.1em;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}

.sold,
.signed {
  .price {
    text-decoration: line-through 1px #fe3464;
  }
}
</style>
