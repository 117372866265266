<template>
  <span class="sold-out-label"> SOLD OUT </span>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$height: 45px;
$width: 120px;

.sold-out-label {
  display: inline-block;
  position: absolute;
  left: 0;
  width: $width;
  line-height: $height;
  font-size: 0.8rem;
  letter-spacing: 0.1em;
  color: white;
  background-color: #b60f0f;
}
</style>
