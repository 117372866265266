<template>
  <transition name="modal">
    <div class="overlay" @click="$emit('close')">
      <div class="panel" @click.stop>
        <div
          class="zoom-area"
          :class="{ active: isActive }"
          :style="[zoomAreaSizeStyle, zoomAreaPositionStyle]"
        >
          <img
            :src="urls[displayPhotoNum]"
            :style="[zoomImgSizeStyle, zoomImgPositonStyle]"
          />
        </div>
        <div class="lens-container" ref="lensContainer">
          <p class="display-photo-number" ref="displayPhotoNumber">
            {{ displayPhotoNum + 1 }}枚目/全{{ getPhotoMaxNum }}枚
          </p>
          <div class="flex-container">
            <button
              v-if="getPhotoMaxNum > 1"
              class="button"
              @click="displayPreviousPhoto"
            >
              &laquo; 前
            </button>
            <img
              class="image"
              :src="urls[displayPhotoNum]"
              ref="image"
              @load="handleImgLoad"
              @mouseenter="activateZoom"
              @mouseleave="diactivateZoom"
              @mousemove="onMouseMove"
            />
            <div
              class="lens"
              ref="lens"
              :style="[lensSizeStyle, lensPositionStyle]"
            ></div>
            <button
              v-if="getPhotoMaxNum > 1"
              class="button"
              @click="displayNextPhoto"
            >
              次 &raquo;
            </button>
          </div>
          <ul v-if="getPhotoMaxNum > 1" class="paging dot">
            <template v-for="x of getPhotoMaxNum" :key="x">
              <li
                :class="displayPhotoNum + 1 == x ? 'active' : ''"
                @click="selectPagerPhoto(x)"
              ></li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    urls: Array,
  },
  data() {
    return {
      isActive: false,
      lensSize: 90,
      lensLimit: {
        x: null,
        y: null,
      },
      lensPositionStyle: {
        top: "-1000px",
        left: "-1000px",
      },
      zoomAreaSize: 180,
      zoomAreaPositionStyle: {
        top: "15px",
        left: `calc(50% - ${this.zoomAreaSize}px)`,
      },
      zoomImgSizeStyle: {
        width: null,
        height: null,
      },
      zoomImgPositonStyle: {
        marginleft: null,
        marginTop: null,
      },
      displayPhotoNum: 0,
    };
  },
  computed: {
    lensSizeStyle() {
      return {
        "--lens-size": `${this.lensSize}px`,
      };
    },
    zoomAreaSizeStyle() {
      return {
        height: `${this.zoomAreaSize}px`,
        width: `${this.zoomAreaSize}px`,
      };
    },
    scale() {
      // return this.zoomAreaSize / this.lensSize;
      return 2;
    },
    getPhotoMaxNum: function () {
      return this.urls.length;
    },
  },
  methods: {
    displayPreviousPhoto() {
      if (this.displayPhotoNum === 0) {
        this.displayPhotoNum = this.getPhotoMaxNum - 1;
      } else {
        this.displayPhotoNum = this.displayPhotoNum - 1;
      }
    },
    displayNextPhoto() {
      if (this.displayPhotoNum === this.getPhotoMaxNum - 1) {
        this.displayPhotoNum = 0;
      } else {
        this.displayPhotoNum = this.displayPhotoNum + 1;
      }
    },
    selectPagerPhoto(number) {
      this.displayPhotoNum = number - 1;
    },
    activateZoom(event) {
      event.stopPropagation();
      this.isActive = true;
    },
    diactivateZoom(event) {
      event.stopPropagation();
      this.isActive = false;
    },
    handleImgLoad(event) {
      this.lensLimit.x = event.target.offsetWidth - this.lensSize;
      this.lensLimit.y = event.target.offsetHeight - this.lensSize;
      this.zoomImgSizeStyle.width = `${
        event.target.offsetWidth * this.scale
      }px`;
      this.zoomImgSizeStyle.height = `${
        event.target.offsetHeight * this.scale
      }px`;
    },
    // clientXY: ブラウザのウィンドウ内の位置。ウィンドウの左上が（0,0）となる。
    // window.pageXYOffset: スクロール量
    onMouseMove(event) {
      // モーダルの左外をクリックするとコンソールにてエラーが生じるためその対策
      if (
        this.$refs.lensContainer === undefined ||
        this.$refs.image === undefined
      ) {
        return;
      }
      let lensContainerRect = this.$refs.lensContainer.getBoundingClientRect();
      let imageRect = this.$refs.image.getBoundingClientRect();
      let photoNumberRect =
        this.$refs.displayPhotoNumber.getBoundingClientRect();

      let mouseX = event.clientX;
      let mouseY = event.clientY;
      let positionX = lensContainerRect.left;
      let positionY = lensContainerRect.top;
      let offsetX = mouseX - positionX; // マウスからcontainerの端っこからの距離（横軸）
      let offsetY = mouseY - positionY - photoNumberRect.height; // マウスからcontainerの端っこからの距離（縦軸）
      let left = offsetX - this.lensSize / 2; // オレンジ枠の左端位置
      let top = offsetY - this.lensSize / 2; // オレンジ枠の上端位置

      // lensContainerとimageの間の隙間
      let imageOffsetX = imageRect.left - lensContainerRect.left;
      let imageOffsetY = imageRect.top - lensContainerRect.top;

      const limitX = this.lensLimit.x + imageOffsetX;
      const limitY = this.lensLimit.y + imageOffsetY;

      if (left > limitX) {
        left = limitX;
      }
      if (top > limitY) {
        top = limitY;
      }
      if (left < 0) {
        left = 0;
      }
      if (top < 0) {
        top = 0;
      }
      // オレンジ枠の位置（カーソルのある位置の情報をもとに計算）
      this.lensPositionStyle.left = `${left}px`;
      this.lensPositionStyle.top = `${top}px`;
      // zoom枠が画像のどの部分を表示するか（オレンジ枠の位置情報をもとに）
      this.zoomImgPositonStyle.marginLeft = `${-(
        (left - imageOffsetX) *
        this.scale
      )}px`;
      this.zoomImgPositonStyle.marginTop = `${-(
        (top - imageOffsetY + photoNumberRect.height) *
        this.scale
      )}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  transition: all 0.5s ease;
}

.button {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  font-size: 16px;
  font-weight: 700;
  color: #333;
  border: none;
  transition: 0.3s;

  &:hover {
    text-decoration: none;
    background-color: #bbbbbb;
  }
}

.display-photo-number {
  text-align: right;
  padding-right: 100px;
}

.panel {
  width: 80%;
  height: 90%;
  display: flex;
  background: #fff;
  position: absolute;
  left: 10%;
  top: 5%;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  .image {
    max-height: 100%;
    max-width: 75%;
  }
}
.modal-enter,
.modal-leave-active {
  opacity: 0;
}

.modal-enter .panel,
.modal-leave-active .panel {
  opacity: 0;
}

.zoom-area {
  display: none;
  position: absolute;
  border: 1px solid #ccc;
  overflow: hidden;
  z-index: 2;

  img {
    // App.vueのスタイルを打ち消す
    max-width: none;

    object-fit: contain;
    object-position: center;
  }

  &.active {
    display: block;
  }
}

.lens-container {
  position: absolute;
  width: 100%;
  height: 100%;

  .lens {
    display: none;
    position: absolute;
    background: #f57716;
    opacity: 0.3;
    pointer-events: none;
    height: var(--lens-size);
    width: var(--lens-size);
  }
  .flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  img {
    &:hover + .lens {
      display: block;
    }
  }
}

.paging {
  display: flex;
  justify-content: space-around;
  padding: 0;
}
.paging.dot {
  width: 40%;
  margin: 5px auto;
}
.paging.dot li {
  border: 1px solid #666;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  list-style-type: none;
}
.paging.dot li.active {
  background: #666;
}
</style>
