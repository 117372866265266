<template>
  <div class="detail" :class="detailClass">
    <pre v-html="property.property_info_detail"></pre>
    <div class="data" :class="dataClass">
      <div class="map">
        <iframe
          :src="property.map_url"
          width="500"
          height="500"
          frameborder="0"
          style="border: 0"
          allowfullscreen
        ></iframe>
      </div>
      <dl>
        <dt>{{ t("single.propertyname") }}</dt>
        <dd>{{ propetyName }}</dd>
        <dt>{{ t("single.propertylocation") }}</dt>
        <dd>{{ propertyLocation }}</dd>
        <dt>{{ t("single.propertytraffic") }}</dt>
        <dd>
          <pre v-html="propertyTraffic"></pre>
        </dd>
        <dt>{{ t("single.propertyexclusivearea") }}</dt>
        <dd>
          <pre>{{ property.area }} m²</pre>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    property: Object,
  },
  computed: {
    detailClass() {
      return "detail-" + this.$store.state.lang;
    },
    dataClass() {
      return "data-" + this.$store.state.lang;
    },
    propetyName() {
      return this.langIsJa()
        ? this.property.site_property_name
        : this.property.property_name;
    },
    propertyLocation() {
      return this.langIsJa()
        ? this.property.property_location
        : this.property.location_detail;
    },
    propertyTraffic() {
      return this.langIsJa()
        ? this.property.transportation_info
        : this.property.traffic;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/styles/mixins";
.detail {
  border-top: 1px solid #eee;
  line-height: 2;
  max-width: 1200px;
  margin: 25px auto 0;
  padding: 20px 0 0;
  width: 90%;

  h2 {
    font-size: 175%;
  }

  > pre {
    font-size: 105%;
    font-weight: 700;
    margin-top: 15px;
    font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "Meiryo",
      "MS Gothic", sans-serif;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  p {
    margin-top: 15px;
  }

  &.detail-en {
    border: none;

    p {
      margin-top: 0;

      &.en-detail-head-large {
        margin-top: 35px;
        font-size: 2rem;
        line-height: 40px;
        letter-spacing: 0.1em;
      }
    }

    pre.en-detail-descript {
      margin-top: 60px;
      font-size: 1rem;
      font-weight: normal;
      color: #717171;
    }
  }
}

.map {
  height: 500px;
  width: 50%;
  @include screensize(small, medium) {
    width: 100%;
    margin-bottom: 12px;
  }

  iframe {
    height: 100%;
    width: 100%;
  }
}

.data {
  display: flex;
  margin-top: 40px;
  flex-direction: row;
  @include screensize(small, medium) {
    flex-direction: column;
  }

  dl {
    font-size: 90%;
    line-height: 2;
    overflow: hidden;
    @include screensize(large) {
      padding-top: 12px;
      padding-left: 35px;
      width: 50%;
    }
    @include screensize(small, medium) {
      width: 100%;
    }
  }

  dt {
    color: #999;
    float: left;
    padding: 12px 0;
    width: 100px;
  }

  dd {
    border-top: 1px solid #eee;
    padding: 12px 0;
    text-align: right;
  }

  pre {
    white-space: pre-line;
  }

  &.data-en,
  &.data-zh {
    dl {
      font-size: 0.8rem;

      dt {
        width: auto;
      }

      dd {
        color: #717171;
      }
    }
  }
}
</style>
