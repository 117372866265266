<template>
  <div class="report-table-wrapper" align="center">
    <table class="report-table">
      <tbody>
        <tr class="header">
          <th v-for="value in header" :key="value">{{ value }}</th>
        </tr>
        <tr class="rows" v-for="row in rows" :key="row[0]">
          <th class="row-header">{{ row[0] }}({{ viewsMap[row[0]] }})</th>
          <template v-for="(value, i) in row">
            <td :key="i" v-if="i != 0">{{ value }}</td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import constants from "@/common/constants/constants";

const ANALYTICS_API_PATH = constants.API_URL_PREFIX + "/site_api/v1/report";
const MONTHLY_API_PATH = ANALYTICS_API_PATH + "/monthly";
const VIEWS_PATH = ANALYTICS_API_PATH + "/views";

export default {
  data() {
    return {
      header: [],
      rows: [],
      viewsMap: {},
    };
  },

  async created() {
    await axios
      .get(MONTHLY_API_PATH, {
        params: {
          year: new Date().getFullYear(),
        },
      })
      .then((response) => {
        const report = response.data.report;
        this.header = report[0];
        this.rows = report.slice(1);
      });

    this.rows.forEach((row) => {
      const page_name = row[0];
      this.$set(this.viewsMap, page_name, null);
      if (page_name.startsWith("/single/")) {
        const prop_id_str = page_name.split("/").pop();
        // 数値にConvert。null,空文字が0になる。digitじゃない文字列やundifinedはNaNになる。
        const prop_id = +prop_id_str;
        if (prop_id === 0 || !Number.isInteger(+prop_id)) return;

        axios
          .get(VIEWS_PATH, { params: { prop_id } })
          .then((response) => {
            this.viewsMap[page_name] = response.data.views;
          })
          .catch((error) => console.error(error));
      }
    });
  },

  methods: {
    async views(page_name) {
      if (page_name.startsWith("/single/")) {
        const prop_id = page_name.split("/").pop();
        if (!+prop_id && Number.isInteger(+prop_id)) {
          return;
        }
        return (
          await axios.get(VIEWS_PATH, {
            params: {
              prop_id,
            },
          })
        ).data.views;
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.report-table-wrapper {
  margin: 0;
  padding: 0;

  .report-table {
    width: 98%;
    border-collapse: collapse;
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
    text-align: left;

    tbody {
      tr {
        height: 3rem;

        th {
          border: 1px solid #000;
          background-color: #808080;
          color: #fff;
          text-align: center;

          &.row-header {
            background-color: #fff;
            color: #000;
            text-align: left;
            padding-left: 15px;
            max-width: 8rem;
          }
        }

        td {
          border: solid 1px #000;
          text-align: center;
        }
      }

      .header {
        th {
          &:first-of-type {
            background-color: #ccc;
            color: #000;
            font-weight: bold;
            text-align: left;
            padding-left: 20px;
          }
        }
      }
    }
  }
}
</style>
