<template>
  <div id="main" class="thankyou">
    <div v-if="langIsJa()">
      <h2>お問い合わせありがとうございました</h2>
      <p>
        このたびは、お問合せいただき、誠にありがとうございました。<br />
        お送りいただきました内容を確認の上、担当者より折り返しご連絡させていただきます。<br />
        お急ぎの場合はお電話にてご連絡くださいませ。
      </p>
    </div>
    <div v-else-if="langIsEn()">
      <h2>Thank you for your inquiry.</h2>
    </div>
    <div v-else>
      <h2>感谢您的咨询</h2>
      <p>
        非常感谢您这次的咨询。<br />
        在确认了您发送的内容之后，负责人会给您回电话。<br />
        如果你有急事，请打电话联系我。
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.thankyou h2 {
  font-size: 150%;
  letter-spacing: 0.1em;
  line-height: 2;
  margin: 150px 0 35px;
  text-align: center;

  + p {
    letter-spacing: 0.1em;
    line-height: 3;
    text-align: center;
  }
}
</style>
