// チャットボットの送信ボタンはチャットボット側で実装しています。
<template>
  <button class="square-button-yellow">{{ text }}</button>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>

<style lang="scss" scoped>
$bgColor: #fff;
$fontColor: #d7aa3b;
$borderColor: #d7aa3b;

.square-button-yellow {
  height: 40px;
  border: solid 1px $borderColor;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: $fontColor;
  padding: 0 15px;
  background: $bgColor;
  cursor: pointer;
}
</style>
