<template>
  <div class="single" :class="{ sold: isSold(property) }">
    <ChatBot
      v-show="!botHiding"
      :propertyType="propInfo.property_type"
      :propId="parseInt($route.params.propId)"
      :onHeaderClickFunc="handleBotHeaderClick"
    />
    <FloatChatBotHeader
      v-show="botHiding"
      :title="fixedHeaderTitle"
      @click="handleBotHeaderClick"
    />

    <!-- 物件動画 -->
    <div
      class="movie-container"
      ref="videoContainer"
      :style="{ height: `${this.videoContainerHeight}px` }"
      v-if="
        (langIsEn() && propInfoEn.video_url) ||
        (langIsZh() && propInfoZh.video_url) ||
        (langIsJa() && propInfo.video_url)
      "
    >
      <div v-show="langIsEn()">
        <VideoPlayer
          v-if="propInfoEn.hasOwnProperty('video_url')"
          :url="propInfoEn.video_url"
          lang="en"
        />
      </div>

      <div v-show="langIsZh()">
        <VideoPlayer
          v-if="propInfoZh.hasOwnProperty('video_url')"
          :url="propInfoZh.video_url"
          lang="zh"
        />
      </div>

      <div v-show="langIsJa()">
        <VideoPlayer
          v-if="propInfo.hasOwnProperty('video_url')"
          :url="propInfo.video_url"
          lang="ja"
        />
      </div>
    </div>
    <div v-else>
      <!-- 動画がない場合 -->
      <img
        src="https://i.vimeocdn.com/video/814949724_600"
        alt="no video"
        :style="{ display: 'flex', margin: '0 auto' }"
      />
    </div>

    <div class="sold-text">
      <div v-if="langIsJa()" class="sub">販売済み</div>
      <div v-if="langIsEn()" class="sub">Sold Out</div>
      <div v-if="langIsZh()" class="sub">已售出</div>
    </div>

    <!-- 概要 -->
    <div class="outline">
      <SingleOutLineWithFormLink
        v-if="!langIsJa()"
        :property="property"
        :views="playNumber"
      />
      <SingleOutLine v-else :property="property" :views="playNumber" />
    </div>

    <!-- 詳細 -->
    <SingleDetail :property="property" />

    <!-- お問い合わせ -->
    <div class="contact">
      <SingleContact :property="propInfo" />
    </div>
  </div>

  <!-- 似ている物件 -->
  <div class="list col3">
    <div v-if="langIsJa()" class="sub">
      <h2 class="sub-main-ja">RELATED</h2>
      <span class="sub-sub">似ている物件</span>
    </div>
    <div v-if="langIsEn()" class="sub">
      <h2 class="sub-main-en">RELATED</h2>
    </div>
    <div v-if="langIsZh()" class="sub">
      <h2 class="sub-main-zh">类似的物件</h2>
      <span class="sub-sub" :class="langState()">RELATED</span>
    </div>
    <ul>
      <li v-for="(item, index) in relatedItems" :key="index">
        <router-link
          :to="'/single/' + item.property_id"
          :class="{ disabled: isSigned(item) }"
        >
          <PropertyEn v-if="langIsEn()" :property="item" />
          <PropertyZh v-else-if="langIsZh()" :property="item" />
          <Property v-else :property="item" />
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import { isSigned, isSold } from "@/common/mixins/mixins.js";
import { LANG_EN, LANG_ZH, LANG_JA } from "@/store/states.js";
import {
  ChatBot,
  FloatChatBotHeader,
  Property,
  PropertyZh,
  PropertyEn,
  VideoPlayer,
  SingleDetail,
  SingleOutLine,
  SingleOutLineWithFormLink,
  SingleContact,
} from "@/components";
import CONSTANTS from "@/common/constants/constants";

const API_URL_PREFIX = CONSTANTS.API_URL_PREFIX;
const SRC_URL_PREFIX = CONSTANTS.SRC_URL_PREFIX;

export default {
  name: "Single",
  mixins: [isSigned, isSold],
  components: {
    ChatBot,
    FloatChatBotHeader,
    Property,
    PropertyZh,
    PropertyEn,
    VideoPlayer,
    SingleDetail,
    SingleOutLine,
    SingleOutLineWithFormLink,
    SingleContact,
  },

  data() {
    return {
      playNumber: 0, // 再生回数
      propInfo: {}, // 物件詳細情報
      propInfoEn: {}, // 物件詳細情報(英語)
      propInfoZh: {}, // 物件詳細情報(中国語)
      relatedItems: [], // 似てる物件
      SRC_URL_PREFIX: SRC_URL_PREFIX,
      botHiding: true,
      fixedHeaderTitle: "Ｌｅａｄ　ＡＩＴａｌｋ",
      videoContainerHeight: 500,
    };
  },

  computed: {
    lang: {
      get() {
        return this.$store.state.lang;
      },
      set(val) {
        this.setLang(val);
      },
    },
    property() {
      if (this.langIsEn()) {
        return this.propInfoEn;
      } else if (this.langIsZh()) {
        return this.propInfoZh;
      } else {
        return this.propInfo;
      }
    },
  },

  async created() {
    /**
     * 物件詳細取得
     */
    await axios
      .get(
        API_URL_PREFIX + "/site_api/v1/properties/" + this.$route.params.propId
      )
      .then((response) => {
        this.propInfo = response.data.property_detail;
        this.videoUrlJa = response.data.property_detail.video_url;

        // 動画の再生回数を取得する(vimeoのAPIアクセスキーが必要でありAuthorizationに設定しAPI呼ぶ)
        let urlRegex = /\/(\d+)/g;
        let result = urlRegex.exec(this.propInfo.video_url);
        if (result != null && !isNaN(result[1])) {
          let videoId = result[1];
          let vimeoApiUrl = `https://api.vimeo.com/videos/${videoId}`;
          axios
            .get(vimeoApiUrl, {
              headers: {
                Accept: "application/vnd.vimeo.*+json;version=3.4	",
                "Content-Type": "application/json",
                Authorization: "Bearer 92bb8349a7d1f48038b284e291e50c69",
              },
            })
            .then((response) => {
              this.playNumber = response.data.stats.plays;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        this.$router.push({ name: "SingleNotFound" });
        console.log(error);
      });

    /**
     * 言語がENだったらEN用の、ZHだったらZH用の物件詳細情報を取得する。
     */
    if (this.hasPropInfo()) {
      if (this.langIsEn()) {
        await this.getEnPropInfo();
      } else if (this.langIsZh()) {
        await this.getZhPropInfo();
      }
    }
    this.setBrowserTitle(this.lang);

    /**
     * 似ている物件取得
     */
    await axios
      .get(
        API_URL_PREFIX +
          "/site_api/v1/properties/sort_by_updated_at?page=1&per_page=3&lang=" +
          this.lang +
          "&exclude_prop_id=" +
          this.$route.params.propId
      )
      .then((response) => {
        this.relatedItems = [...response.data];
      })
      .catch((error) => {
        console.log(error);
      });

    /**
     * 物件のアクセス数を増やす
     */
    await axios
      .post(
        API_URL_PREFIX +
          "/site_api/v1/properties/" +
          this.$route.params.propId +
          "/access"
      )
      .catch((error) => {
        console.log(error);
      });
  },

  watch: {
    async lang(newVal) {
      this.setBrowserTitle(newVal);
      if (!this.hasPropInfo()) return;
      if (newVal === LANG_EN && !Object.keys(this.propInfoEn).length) {
        await this.getEnPropInfo();
      } else if (newVal === LANG_ZH && !Object.keys(this.propInfoZh).length) {
        await this.getZhPropInfo();
      }
    },
  },

  methods: {
    setBrowserTitle: function (lang) {
      // ブラウザタブのタイトルの設定
      let route = this.$route;
      if (route.meta.title) {
        document.title = route.meta.title;
      }
      if (lang === LANG_EN && this.propInfoEn.location) {
        document.title += ` ${this.propInfoEn.location}`;
      } else if (lang === LANG_ZH && this.propInfoZh.location) {
        document.title += ` ${this.propInfoZh.location}`;
      } else if (lang === LANG_JA && this.propInfo.property_location) {
        document.title += ` ${this.propInfo.property_location}`;
      }
    },

    handleBotHeaderClick: function () {
      this.botHiding = !this.botHiding;
    },

    async getPropInfo() {
      try {
        this.propInfo = (
          await axios.get(
            API_URL_PREFIX +
              "/site_api/v1/properties/" +
              this.$route.params.propId
          )
        ).data.property_detail;
      } catch (error) {
        throw error.response;
      }
    },

    /**
     * 言語が英語の際に使用する物件情報を取得する
     * @return propInfoEn
     * @throws error.response
     */
    async getEnPropInfo() {
      try {
        const enResult = (
          await axios.get(
            API_URL_PREFIX +
              "/site_api/v1/properties/en/" +
              this.$route.params.propId
          )
        ).data.property_en;
        this.propInfoEn = {
          ...this.propInfo,
          ...enResult,
        };
      } catch (error) {
        throw error.response;
      }
    },

    /**
     * 言語が中国語の際に使用する物件情報を取得する
     * @return propInfoZh
     * @throws error.response
     */
    async getZhPropInfo() {
      try {
        const zhResult = (
          await axios.get(
            API_URL_PREFIX +
              "/site_api/v1/properties/zh/" +
              this.$route.params.propId
          )
        ).data.property_zh;
        this.propInfoZh = {
          ...this.propInfo,
          ...zhResult,
        };
      } catch (error) {
        throw error.response;
      }
    },
    /**
     * ビデオコンテナの高さを計算する
     * @return number
     */
    setVideoContainerHeight() {
      const videoContainer = this.$refs.videoContainer;
      if (videoContainer) {
        const width = videoContainer.offsetWidth;
        const height = (width / 16) * 9;
        this.videoContainerHeight = height;
      }
    },
    /**
     * 物件情報が取得できたかを判定する
     */
    hasPropInfo() {
      return Object.keys(this.propInfo).length > 0;
    },
  },
  mounted() {
    this.setVideoContainerHeight();
    window.addEventListener("resize", this.setVideoContainerHeight);
  },
  beforeUnmount() {
    window.addEventListener("resize", this.setVideoContainerHeight);
  },
};
</script>

<style lang="scss" scoped>
.single {
  .sold-text {
    display: none;
    justify-content: center;
    margin: 25px 0;
    color: red;
    font-size: 260%;
  }

  .movie-container {
    max-width: 960px;
    margin: 75px auto 0;
    width: 90%;
    background: #000;

    > div {
      height: 100%;
    }

    // &.en {
    //   margin-top: 0;
    //   width: 100%;
    // }
  }

  .movie {
    background: #000;
    max-width: 960px;
    top: 20px;

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  .contact {
    max-width: 1200px;
    margin: 25px auto 0;
    width: 90%;
  }

  .outline {
    position: relative;
    max-width: 1200px;
    margin: 30px auto 0;
    width: 90%;
  }
}

.sold {
  .sold-text {
    display: flex;
  }
}
</style>
