<template>
  <div id="main" class="about">
    <div class="hero">
      <div class="copy serif" :class="langState()">
        {{ t("about.copy") }}
        <p v-if="langIsEn() || langIsZh()" class="copy-sub">TOKYO | JAPAN</p>
      </div>
    </div>
    <div class="icon" :class="langState()">
      <img src="../assets/img/icon.png" alt="" />
    </div>

    <section class="message">
      <div v-if="langIsEn()" class="message-en">
        <p v-if="langIsEn()" class="descript descript-en">
          Now that people have constant access to the Internet, the time to make
          full use of IT as a measure to solve business problems of various
          companies and situations, is now.<br />
          We are a global IT platform that focuses on the real estate industry
          and combines digital content with IT technology.<br />
          We procure the latest technologies such as video media, AI, robots,
          etc.<br />
          for investors who are interested in real estate, and for those looking
          to purchase.<br />
          We are taking on challenges every day to develop a real estate
          matching service that provides real estate knowledge, property
          information, and area information.<br />
          "What is the true value for our customers?" Through meetings and
          consultations, we will give shape to the "ideal image" that our
          customers envision.<br />
          That is our mission.<br />
          For-sale housing, single-building condominiums, office buildings,
          hotel properties, private lodgings, you name it.<br />
          By accurately and succinctly understanding your needs, we are creating
          safe and comfortable homes.<br />
          To that end, we are working every day to improve customer satisfaction
          without compromising.
        </p>
      </div>

      <p v-else-if="langIsZh()" class="message-zh">
        如今人们可以随时随地连接到互联网，作为解决各种企业和场景的商业课题的方法之一，<br />
        我们认为现在是最适合运用IT的时代。 <br />
        我们是一个国际IT平台，专注于房地产业务，致力于将数码内容与IT技术相结合。<br />
        我们面向对房地产感兴趣的投资者和潜在购买者， <br />
        为您提供最新技术，如视频媒体，人工智能和机器人。<br />
        我们每天都致力于开发房地产匹配服务，提供房地产知识，房地产信息和区域信息。<br />
        “对于顾客来说，真正的价值是什么？”<br />
        我们公司进行了无数次的讨论，<br />
        我们致力于将您心中的理想变成现实……<br />
        我认为这是我们的使命。<br />
        分售住宅，1栋公寓，写字楼，酒店，民宿，旅馆等<br />
        我们精确的捕捉每个项目的特色和需求，致力于打造出更加舒适安全的”理想家“。<br />
        为此，我们每天都在努力提高客户的满意度，并且不曾妥协，务求精益求精。
      </p>

      <p v-else class="message-ja">
        人々が常にインターネットへ接続可能となった今、様々な企業や場面のビジネス課題を解決する方策として、<br />
        ITを駆使する事こそが最も適した時代になったと考えています。<br />
        私たちは、不動産ビジネスへ焦点を当て、デジタルコンテンツとITテクノロジーを組み合わせる、<br />
        グローバルITプラットフォーマーです。<br />
        不動産に関心のある投資家及び、購入希望者に対し、動画メディア・AI・ロボットなどの<br />
        最新テクノロジーを調達し、<br />
        不動産に関する知識・物件情報・エリア情報等を提供する、不動産マッチングサービスを展開しようと<br />
        日々チャレンジをしています。<br />
        「お客さまにとって、本当の価値とは何か」。<br />
        当社では打ち合わせを何度となく行い、<br />
        お客さまが思い描く“理想のイメージ”をカタチにしていく･･･。<br />
        それが私どもの使命と考えています。<br />
        分譲住宅、1棟マンション、オフィスビル、ホテル物件、民泊旅館など、<br />
        それぞれの特色やニーズを的確に捉え、より快適で安全な住まいづくりを行っています。<br />
        そのためには一切の妥協をせず、お客さまの満足度向上を目指し、日々取り組んでいます。
      </p>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    let route = this.$route;
    if (route.meta.title) {
      document.title = route.meta.title;
    }
  },
};
</script>

<style lang="scss" scoped>
.about .hero {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.about .hero .copy {
  font-size: 400%;
  letter-spacing: 0.5em;
  line-height: 200px;
  text-align: center;
  text-indent: 0.5em;
  padding-top: 50px;

  &.en {
    width: 100%;
    font-size: 3rem;
    line-height: 70px;
    letter-spacing: 0.1em;
  }

  &.zh {
    width: 100%;
    font-size: 3rem;
    line-height: 70px;
    letter-spacing: 0.1em;
    color: #d7aa3b;
  }

  .copy-sub {
    font-size: 0.33em;
  }
}

.about .icon {
  margin: 50px auto 100px;
  width: 90%;
  max-width: 400px;

  &.en,
  &.zh {
    margin-top: 100px;
  }
}

.message {
  letter-spacing: 0.1em;
  text-align: center;
  width: 90%;
  max-width: 900px;
  margin: 0 auto;

  .head {
    font-size: 2rem;
    line-height: 40px;
    margin-bottom: 50px;
  }

  .message-en {
    margin: auto;
    line-height: 45px;

    .descript-en {
      text-align: left;
      font-size: 1.3rem;
      color: #000;
      font-family: monospace;
    }

    .descript-ja {
      line-height: 3;
    }
  }

  .message-zh {
    margin: auto;
    line-height: 40px;
  }

  .message-ja {
    line-height: 3;
  }
}
</style>
