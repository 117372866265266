<template>
  <div class="sold-out-label-zh">
    <img src="@/assets/img/sold_zh.svg" />
  </div>
</template>

<style lang="scss" scoped>
$height: 50px;
$width: 50px;

.sold-out-label-zh {
  position: absolute;
  margin: 10px;
  left: 0;

  img {
    width: $width;
    height: $height;
  }
}
</style>
