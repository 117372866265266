<template>
  <div id="main" class="archive">
    <div class="list col4">
      <div class="sub">
        <h2 :class="'sub-main-' + langState()">
          {{ t("archive.all") }}
        </h2>
        <span v-if="langIsJa()" class="sub-sub">すべての物件</span>
      </div>
      <ul>
        <li v-for="(item, index) in allItems" :key="index">
          <router-link
            :to="'/single/' + item.property_id"
            :class="{ disabled: isSigned(item) }"
          >
            <PropertyEn v-if="langIsEn()" :property="item" />
            <PropertyZh v-else-if="langIsZh()" :property="item" />
            <Property v-else :property="item" />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { isSigned } from "@/common/mixins/mixins.js";
import { Property, PropertyEn, PropertyZh } from "@/components";
import axios from "axios";
import CONSTANTS from "@/common/constants/constants";
const API_URL_PREFIX = CONSTANTS.API_URL_PREFIX;

export default {
  name: "Archive",
  mixins: [isSigned],
  components: {
    Property,
    PropertyEn,
    PropertyZh,
  },

  data() {
    return {
      allItems: [],
    };
  },

  mounted() {
    let route = this.$route;
    if (route.meta.title) {
      document.title = route.meta.title;
    }
  },

  created() {
    axios
      .get(
        API_URL_PREFIX +
          "/site_api/v1/properties/sort_by_updated_at?page=1&per_page=20&lang=" +
          this.$store.state.lang
      )
      .then((response) => {
        this.allItems = [...response.data];
      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    setPropIdForSingle: function (propId) {
      sessionStorage.setItem("propid", propId);
    },
  },
};
</script>

<style lang="scss" scoped>
.archive .list {
  margin-top: 150px;
}
</style>
