<template>
  <div>
    <LangBtn lang="ja" v-model="lang" />
    <span class="dlm">|</span>
    <LangBtn lang="en" v-model="lang" />
    <span class="dlm">|</span>
    <LangBtn lang="zh" v-model="lang" />
  </div>
</template>

<script>
import LangBtn from "./LangBtn";
import { mapMutations } from "vuex";

export default {
  components: {
    LangBtn,
  },

  computed: {
    lang: {
      get() {
        return this.$store.state.lang;
      },
      set(val) {
        this.setLang(val);
      },
    },
  },
  methods: {
    ...mapMutations(["setLang"]),
  },
};
</script>

<style lang="scss" scoped>
.lang-btn {
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &.selected {
    font-weight: bold;
  }
}

.dlm {
  margin: 0 5px;
}
</style>
