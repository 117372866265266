<template>
  <button :style="styles" class="square-button-red">{{ text }}</button>
</template>

<script>
export default {
  props: {
    text: String,
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "40px",
    },
  },
  computed: {
    styles() {
      return {
        width: this.width,
        height: this.height,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$bgColor: #b90f0f;
$fontColor: #fff;
$borderColor: #b90f0f;

.square-button-red {
  height: 40px;
  border: solid 1px $borderColor;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: $fontColor;
  padding: 0 15px;
  background: $bgColor;
  cursor: pointer;
}
</style>
