// チャットボットの送信ボタンはチャットボット側で実装しています。
<template>
  <button :style="styles" class="square-button">{{ text }}</button>
</template>

<script>
export default {
  props: {
    text: String,
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "40px",
    },
    bgColor: {
      type: String,
      default: "#D7AA3B",
    },
    fontColor: {
      type: String,
      default: "#FFF",
    },
    borderStyle: {
      type: String,
      default: "none",
    },
  },
  computed: {
    styles() {
      return {
        width: this.width,
        height: this.height,
        "background-color": this.bgColor,
        color: this.fontColor,
        border: this.borderStyle,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.square-button {
  outline: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.1em;
  padding: 0 15px;
  cursor: pointer;
}
</style>
