<template>
  <div :class="{ sold: isSold(property), signed: isSigned(property) }">
    <SignedLabel v-if="isSigned(property)" />
    <span class="thumb">
      <img :src="imageSrc" alt="thumb" />
    </span>
    <div class="sold-text">卖</div>
    <p class="unknow">DETACHED HOUSE</p>
    <p class="address">{{ property.location }}</p>
    <p class="price">{{ property.price }} <span class="jpy">JPY</span></p>
    <span class="access">
      <strong>{{ property.access_number }}</strong> {{ t("archive.click") }}
    </span>
  </div>
</template>

<script>
import SignedLabel from "./SignedLabelZh";
import { isSigned, isSold } from "@/common/mixins/mixins.js";

export default {
  name: "PropertyZh",
  mixins: [isSigned, isSold],
  components: {
    SignedLabel,
  },

  props: {
    property: Object,
  },

  computed: {
    imageSrc() {
      if (!this.property.property_photo_link) {
        return "https://i.vimeocdn.com/video/814949724_600";
      }
      if (!this.property.photo_link.includes("_600")) {
        const link = this.property.photo_link.split("_");
        const photo_link = link[0];
        return photo_link + "_600";
      } else {
        return this.property.photo_link;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  position: relative;
}

.thumb {
  line-height: 1.5;
  display: block;
  margin-bottom: 18px;

  img {
    display: block;
  }
}

p {
  text-align: left;

  &.unknow {
    font-size: 0.8em;
    margin-bottom: 12px;
    font-family: "Montserrat", sans-serif;
  }

  &.address {
    font-size: 1em;
    margin-bottom: 15px;
  }

  &.price {
    font-size: 1.8em;
    margin-bottom: 30px;

    .jpy {
      font-size: 0.7em;
    }
  }
}

.sold-text {
  display: none;
  margin: 18px 0;
  color: red;
  font-size: 1.1em;
}

.sold,
.signed {
  .price {
    background-image: linear-gradient(#fe3464, #fe3464);
    background-position: 0 50%;
    background-size: 100% 1px;
    background-repeat: no-repeat;
    color: #888;
    text-decoration: none;
    width: fit-content;
  }
}

.sold {
  .sold-text {
    display: block;
  }
}

.access {
  color: #666;
  display: block;
  font-size: 75%;
  text-align: left;

  strong {
    color: #222;
    font-size: 115%;
  }
}
</style>
