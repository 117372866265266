import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueScrollTo from "vue-scrollto";
import VueGtag from "vue-gtag";
import langMixin from "./lang";
import VueGoogleMaps from "@fawmi/vue-google-maps";

const app = createApp(App);

app.use(VueScrollTo);
if (["production", "staging"].includes(process.env.NODE_ENV)) {
  // Google Analytics
  app.use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID },
  });
}

app.use(VueGoogleMaps, {
  load: { key: process.env.VUE_APP_GOOGLE_MAP_API },
});
app.mixin(langMixin);

app.use(router);
app.use(store);
app.mount("#app");
