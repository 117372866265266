<template>
  <div v-if="error" class="movie error">
    <video
      src="http://dluip6n21rdy.cloudfront.net/pleasewait.mp4"
      controls
      autoplay
      muted
    />
  </div>
  <div v-else class="movie" :id="containerId" />
</template>

<script>
import Player from "@vimeo/player";
export default {
  props: {
    url: {
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      error: false,
    };
  },

  computed: {
    containerId() {
      return `movie-${this.lang}`;
    },
  },

  mounted() {
    this.createPlayer(this.url, this.containerId);
  },

  methods: {
    createPlayer(url, containerId) {
      new Player(containerId, {
        id: url,
        width: "960",
        height: "535",
      })
        .loadVideo()
        .then()
        .catch((error) => {
          switch (error.name) {
            // ESLint対応でcaseをブロックで括る
            case "TypeError": {
              // 動画読み込み時のエラーをキャッチするため、必要のないloadVideo()を差し込んでいる（もっと良い方法があったら修正して欲しい）
              // 正しく動画を読み込めている場合でも、loadVideo()の引数がないので必ずエラーになるが、
              // そのエラーはTypeErrorになるので、TypeErrorの時は普通に動画を表示させるようにする。
              // *引数はあるが、URLが間違えている場合は、error.messageで分かるので、その分岐を入れても良いかもしれない。

              // cssに記載してもstyleが当たらないのでここで適用させる。
              const frame = document.querySelector(`#${containerId} iframe`);
              if (frame !== null) {
                frame.style.width = "100%";
                frame.style.height = "100%";
              }
              break;
            }
            default:
              console.log(error);
              // ここでPleaseWait動画を表示する。
              this.error = true;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.movie {
  height: 100%;
  background: #000;
  max-width: 960px;
  top: 20px;

  &.error {
    video {
      width: 100%;
    }
  }
}
</style>
